
module.exports = {
   Provincias: [{
      Nombre: "Buenos Aires",
      Descripcion:"Buenos Aires es una ciudad cosmopolita y un importante destino turístico. Su compleja infraestructura la convierte en una de las metrópolis de mayor importancia de América y es una ciudad global de categoría 'alfa', dadas sus influencias en el comercio, finanzas, moda, arte, gastronomía, educación, entretenimiento y principalmente en su marcada cultura. Según un estudio de calidad de vida (2019) de Mercer Human Resource Consulting, la ciudad se encuentra en la posición 91 a nivel mundial y segunda de América Latina tras Montevideo. Su renta per cápita es de las más altas de la región.18​19​ En 2022, fue la ciudad más visitada de Latinoamérica.​ Es considerada una de las 25 ciudades más influyentes del mundo.",
      Frases: ["No soporto esa ciudad tiene mucha contaminacion","La provincia donde fue tenia todas las vocales, que loco"],///hippie
      Frases2: ["Parece que aterrizaria en el Aeropuerto Jorge Newbery","No se muy bien para donde fue pero mayoria de los vuelos van alli"],////random
      Frases3: ["Se marcho a conocer cancha de River","Queria ir para Ituzaingo"],
      imagen:"../../Assets/buenosaires.jpg"
   },
   {
      Nombre: "Catamarca",
      Descripcion:"Catamarca es una provincia del noroeste de Argentina, que limita con Chile. Sus impresionantes paisajes incluyen sierras adornadas con cactus en el este y altiplanos inhóspitos andinos en el noroeste. La icónica Ruta 40 asciende a las ciudades de Belén y Londres, conocidas por los ponchos tejidos a mano y las ruinas incas cercanas de El Shincal. La Catedral Basílica de Nuestra Señora del Valle tiene un estilo neoclásico y atrae a peregrinos a Catamarca, la capital provincial.",
      Frases: ["Queria conocer al catadiaz","Se fue para arriba de la Rioja mas o menos"],
      Frases2: ["Se marcho a San fernando del Valle","Esa provincia tiene Londres, en Argentina"],
      Frases3: ["Se fue al norte, no era salta","Mmm el sector más importante que desarrolla la provincia es el minero "],
      imagen:"../../Assets/catamarca.jpg"
   },
   {
      Nombre: "Chaco",
      Descripcion:"  Chaco, en el texto de la constitución provincial Provincia del Chaco, ​ es una de las veintitrés provincias de la República Argentina. A su vez, es una de las veinticuatro jurisdicciones autogobernadas que conforman el país y uno de los veinticuatro distritos electorales legislativos nacionales.​​ ",
      Frases: ["  Se fue a cagarse de calor, no hay costanera ahi","  Tenia una camiseta de For Ever"],
    Frases2: ["  Se fue al secreto de la Argentina","  Se fue a la Ciudad de las Esculturas"],
      Frases3: ["  Queria conocer el zoologico de saenz peña","  Estaba queriendo ver el Puente gral Belgrano"],
      imagen:"../../Assets/chaco.jpg"

   },
   {
      Nombre: "Chubut",
      Descripcion:"  Chubut es una provincia ubicada en la región de la Patagonia en Argentina. Limita al oeste con Chile, al norte con las provincias de Río Negro y Neuquén, al este con el océano Atlántico y al sur con Santa Cruz. La capital de la provincia es Rawson, y la ciudad más poblada es Comodoro Rivadavia. Chubut se destaca por su diversidad geográfica, que incluye la meseta, la cordillera de los Andes, y la costa atlántica. La economía de la provincia está vinculada principalmente a la actividad agropecuaria, la pesca, y la industria petrolera.",
      Frases: ["  Se marcho a un lugar de la patagonia","  Solo se q iba al sur"],
    Frases2: ["  e marcho a Comodoro ","  Se iba para Rawson"],
      Frases3: ["  Queria ver las vastas llanuras entre la cordillera de los Andes y el océano Atlántico, ah a re flasheaba","  Si, alli predomina el clima árido con gran amplitud térmica con una zona de oasis"],
      imagen:"../../Assets/chubut.jpg"

   },
   {
      Nombre: "Córdoba",
      Descripcion:"  Córdoba, la capital de la provincia argentina del mismo nombre, es conocida por su arquitectura colonial española. Alberga la Manzana Jesuítica, un complejo del siglo XVII con claustros activos, iglesias y el campus original de la Universidad Nacional de Córdoba, una de las universidades más antiguas de Sudamérica. El punto central de la ciudad es la Plaza San Martín y la Catedral de Córdoba de estilo neobarroco",
      Frases: ["  Que Paz, Carlos","  Fue al Oktober fest en Villa gral Belgrano"],
      Frases2: ["  Se iba Escuchando cuarteto y tomando fernet","  Tenia q ver un partido de Talleres de local"],
      Frases3: ["  Queria conocer al a Mona Gimenez","  Queria un cuartetazo violento"],
      imagen:"../../Assets/cordoba.jpg"
   },
   {
      Nombre: "Corrientes",
      Descripcion:"  Corrientes es una provincia en el noreste subtropical de Argentina, delimitada por los ríos Paraná y Uruguay. Los Esteros del Iberá, un área inmensa de lagos y humedales que alberga yacarés, anacondas, carpinchos y más de 350 especies de aves, es un destino popular para el ecoturismo. La región es conocida por la pesca con mosca del dorado, y algunas de sus estancias (haciendas) ofrecen alojamiento. ",
      Frases: ["  Dijo que queria tomar sol al lado del puente General Belgrano","  Se iba con un amigo q le decian el Yacare"],
      Frases2: ["  Se iba a conocer los Esteros del Ibera","  Iba escuchando Kilometro 11"],
      Frases3: ["  Iba a ver el Festival del chamame","  Queria conocer donde fue la guerra de la triple alianza"],
      imagen:"../../Assets/corrientes.jpg"
   },
   {
      Nombre: "Entre Ríos",
      Descripcion:"  Entre Ríos es una provincia de la región de Mesopotamia en el noreste de Argentina, entre los ríos Paraná y Uruguay. Es conocida por sus fuentes termales, como las de Federación, y su tradición de cultivar la yerba mate y beber el mate. Alberga 2 parques nacionales: Predelta, abundante en especies de aves, y El Palmar, una de las reservas que quedan de la palma yatay que alguna vez cubrió la región.",
      Frases: ["  Se marcho a una provincia entre rios","Iba a ver carnavales"],
      Frases2: ["  Queria ir a santa fe pero no habian pasajes, va a una ciudad en frente y pasaba el tunel","  Iba a ver el palacio de Urquiza"],
      Frases3: ["  Queria conocer el tunel subfluvial",  "  Despues de aterrizar se pasaba a Santa fe"],
      imagen:"../../Assets/entrerios.jpg"
   },
   {
      Nombre: "Formosa",
      Descripcion:"  Formosa (en el texto de la Constitución provincial: Provincia de Formosa) es una de las veintitrés provincias de la República Argentina.​ A su vez, es uno de los veinticuatro estados autogobernados que conforman el país y uno de los veinticuatro distritos electorales legislativos nacionales.​ Su capital y ciudad más poblada es la homónima Formosa. Está ubicada en el noreste del país, en la región del Norte Grande Argentino, limitando al norte con el río Pilcomayo que la separa de Paraguay, al este con el río Paraguay que la separa de nuevo de Paraguay, al sur con los ríos Bermejo y Teuco que la separan de Chaco, y al oeste con Salta, mediante un meridiano.",
      Frases: ["  Se marcho, queria cruzarse a Alberdi a comprar mochilas","  De alli se pasaba a otro pais por puerto Elsa"],
      Frases2: ["  Se fue quejandose de Gildo","  Iba a un puelbo que se llama el Colorado"],
      Frases3: ["  Se fue a tomar terere en la vereda con 40 grados de calor","  De alli pasabaa Asuncion"],
      imagen:"../../Assets/formosa.jpg"
   },
   {
      Nombre: "Jujuy",
      Descripcion:"  Jujuy, provincia del remoto noroeste de Argentina, se caracteriza por sus espectaculares formaciones rocosas y colinas de la Quebrada de Huamahuaca. Este valle y sus aldeas quechuas se ubican en el norte de la capital provincial y el acceso regional, San Salvador de Jujuy. En el sur del valle, las laderas rocosas del icónico Cerro de los Siete Colores sobresalen de la villa colonial Purmamarca.",
      Frases: ["  Dijo que queria ir lo mas al norte posible","  Se marcho al coso de los 7 colores"],
      Frases2: ["  decia que de ushuaia iba hacia alli","  San Salvador es lindo en esta epoca"],
      Frases3: ["  Queria cruzarse a Bolivia","  La provincia tenia una Y griega"],
      imagen:"../../Assets/jujuy.jpg"
   },
   {
      Nombre: "La Pampa",
      Descripcion:"  La Pampa es una provincia en el centro de Argentina, en las amplias llanuras pampeanas. Hay muchas estancias sobre los terrenos fértiles cerca de la relajada Santa Rosa, capital de la provincia. En el sudoeste, el Parque Nacional Lihué Calel alberga lagartijas, pumas y guanacos. Al sur de Santa Rosa, la reserva Parque Luro es conocida por su bosque de espinosos árboles caldenes, donde viven venados rojos, jabalíes y pájaros carpinteros.",
      Frases: ["  Se marcho a Santa Rosa","  No recuerdo pero comenzaba con 'La'"],
      Frases2: ["  En el destino del vuelo decia RSA","  El nombre de la provincia que iba significa llano, llanura'"],
      Frases3: ["  Se marcho a conocer Reserva Provicial Parque Luro","  Iba a una provincia en el centro de Argentina, en las amplias llanuras pampeanas"],
      imagen:"../../Assets/lapampa.jpg"
   },
   {
      Nombre: "La Rioja",
      Descripcion:"  La Rioja es una comunidad autónoma en el norte de España, con Logroño como su capital y ciudad más grande. Limita al norte con el País Vasco, al sur con Castilla y León, al este con Navarra y al oeste con Castilla-La Mancha. La región es conocida por sus vinos, especialmente los vinos tintos elaborados con la variedad de uva Tempranillo. La Rioja también tiene un rico patrimonio cultural y histórico, con numerosos monumentos, iglesias y festivales. Además de la viticultura, la agricultura y la gastronomía son aspectos destacados de la región. ",
      Frases: ["  Se marcho a ver de donde salio Menem", "  La capital se llama igual a la provincia"],
      Frases2: ["  Lo unico que se es que compro un chip con caracteristica 380","  Mira, te puedo decir que estaba poblado por los diaguitas, vio la llegada de los españoles a mediados del siglo XVI y fue testigo de la valiente resistencia indígena."],
      Frases3: ["  Queria tomar unos vinos pero no iba a Mendoza", "  LR son las iniciales del destino"],
      imagen:"../../Assets/larioja.jpg"
   },
   {
      Nombre: "Mendoza",
      Descripcion:"Mendoza es una ciudad de la región de Cuyo en Argentina y es el corazón de la zona vitivinícola argentina, famosa por sus Malbecs y otros vinos tintos. Sus distintas bodegas ofrecen degustaciones y visitas guiadas. La ciudad tiene calles amplias y frondosas rodeadas de edificios modernos y art déco, y con plazas más pequeñas que rodean la Plaza Independencia, sitio del Museo Municipal de Arte Moderno subterráneo, que exhibe arte moderno y contemporáneo.",
      Frases: ["  Se marcho a tomar vino","  Llueve muy poco por esa zona"],
      Frases2: ["  Fue a ver el Aconcagua","  La actividad economica principal de la provincia es el petroleo, quienr diria detras del vino y turismo"],
      Frases3: ["  Pregunto por Tours de vinos","  Fue ala cancha de Gosoy cruz"],
      imagen:"../../Assets/mendoza.jpg"
   },
   {
      Nombre: "Misiones",
      Descripcion:"  La Provincia de Misiones se encuentra ubicada entre los paralelos 25º 28´ y 28º 10´de Latitud Sur y los meridianos 53º 38´y 56º 03´de Longitud Oeste en la Región Nordeste de la República Argentina. Casi la totalidad de sus límites está conformada por ríos, el Iguazú al Norte, el Paraná al Oeste, el Pepirí Guazú y el Uruguay al Este y el Chimiray al Sur. Más del 80% de sus límites son internacionales, lindando al Norte y al Este con La República del Brasil, y al Oeste con la República del Paraguay.      ",
      Frases: ["  Se marcho a las cataratas","  Se compra buena yerba por esa provincia"],
      Frases2: ["  Parece que de Posadas iba hasta Wanda","  Se iba apuerto Rico"],
      Frases3: ["  Queria Yerba exclusiva de Andresito","  Fue a ver si conocia un coati"],
      imagen:"../../Assets/misiones.jpg"
   },
   {
      Nombre: "Neuquén",
      Descripcion:"Neuquén es una provincia argentina en el noroeste de la Patagonia. En su lado sur, se encuentra el icónico volcán Lanín con su cono nevado. San Martín de los Andes, una ciudad de estilo alpino en el lago Lácar, está conectada a través del Camino de los Siete Lagos con Villa La Angostura, una ciudad en un lago profundo y claro rodeado del Parque Nacional Nahuel Huapi. Cerca de la ciudad, el Parque Nacional Los Arrayanes es una península arbolada con arrayanes de 300 años de antigüedad",
      Frases: ["  Creo que el nombre de la provincia era un palindromo, soy un hippie culto","  Creo que el nombre de la provincia era un palindromo, soy un hippie culto"],
      Frases2: ["  Si me das cien pesos te digo que se fue a Neuquen","  Si me das cien pesos te digo que se fue a Neuquen"],
      Frases3: ["  Se andaba quejando de Mariano Gaido, mejor que no se cruce cuando vaya ","  Se andaba quejando de Mariano Gaido, mejor que no se cruce cuando vaya "],
      imagen:"../../Assets/neuquen.jpg"
   },
   {
      Nombre: "Río Negro",
      Descripcion:"Neuquén es una provincia argentina en el noroeste de la Patagonia. En su lado sur, se encuentra el icónico volcán Lanín con su cono nevado. San Martín de los Andes, una ciudad de estilo alpino en el lago Lácar, está conectada a través del Camino de los Siete Lagos con Villa La Angostura, una ciudad en un lago profundo y claro rodeado del Parque Nacional Nahuel Huapi. Cerca de la ciudad, el Parque Nacional Los Arrayanes es una península arbolada con arrayanes de 300 años de antigüedad. ",
      Frases: ["  Se marcho a bariloche","  Se marcho a bariloche"],
      Frases2: ["  Reservo una cabaña en el cerro catedral","  Reservo una cabaña en el cerro catedral"],
      Frases3: ["  Solamente se que fue al sur, el hippie capaz tenga mas datos","  Solamente se que fue al sur, el hippie capaz tenga mas datos"],
      imagen:"../../Assets/rionegro.jpg"
   },
   {
      Nombre: "Salta",
      Descripcion:"Salta es una capital provincial del noroeste montañoso de Argentina. Se fundó en 1582 y es conocida por su arquitectura colonial española y su herencia andina. La ciudad se centra en la Plaza 9 de Julio, elegante y rodeada de cafés, en torno a la cual se ubican la Catedral de Salta y El Cabildo, un ayuntamiento del siglo XVIII transformado en museo. En las cercanías, está el Museo de Arqueología de Alta Montaña (MAAM), que alberga artefactos incas y momias. ",
      Frases: ["  Se marcho al noroeste","  Se marcho al noroeste"],
      Frases2: ["  Queria saber por que le dicen La linda","  Queria saber por que le dicen La linda"],
      Frases3: ["  Queria ver el partido de Gimnasia y tiro vs River, parece que se jugaba en el norte","  Queria ver el partido de Gimnasia y tiro vs River, parece que se jugaba en el norte"],
      imagen:"../../Assets/salta.jpg"
   },
   {
      Nombre: "San Juan",
      Descripcion:"  San Juan es una ciudad en el valle al este de la cordillera de los Andes, en la zona central occidental de Argentina. En el centro está la Casa Natal de Sarmiento, donde nació el presidente del siglo XIX y escritor Domingo Faustino Sarmiento. Cerca está el Museo Provincial de Bellas Artes Franklin Rawson, que exhibe pinturas de artistas argentinos y europeos. El Museo Histórico Provincial Agustín Gnecco exhibe muebles, estampillas y monedas locales. ",
      Frases: ["  Agarro un avion cuyo destino era Cuyo","Agarro un avion cuyo destino era Cuyo"],
      Frases2: ["  Estaba ansioso por conocer al que estaba en el billete de 50 pesos","  Estaba ansioso por conocer al que estaba en el billete de 50 pesos"],
      Frases3: ["  Queria ver la casa de Sarmiento","  Queria ver la casa de Sarmiento"],
      imagen:"../../Assets/sanjuan.jpg"
   },
   {
      Nombre: "San Luis",
      Descripcion:"  an Luis es una ciudad del centro de Argentina, en las montañas de las Sierras Grandes. Junto a la frondosa plaza principal, Plaza Pringles, hay una catedral del siglo XIX con una fachada neoclásica y dos campanarios gemelos. En las cercanías, se encuentra el Museo Provincial Dora Ochoa de Masramón, que exhibe una amplia variedad de objetos, desde fósiles y restos prehistóricos hasta arte y antigüedades. El extenso Parque de las Naciones tiene senderos bordeados de árboles que lo entrecruzan.",
      Frases: ["  Se marcho San Luis","Se marcho San Luis"],
      Frases2: ["  Si, San luis es lindo en esta epoca....","  Si, San luis es lindo en esta epoca...."],
      Frases3: ["  Queria ver las Sierras de las Quijadas","  Queria ver las Sierras de las Quijadas:"],
      imagen:"../../Assets/sanluis.jpg"
   },
   {
      Nombre: "Santa Cruz",
      Descripcion:"  Santa Cruz es una provincia en la Patagonia argentina en el sur del país. Se ubica entre la cordillera de los Andes al oeste y el océano Atlántico al este. El Parque Nacional los Glaciares, donde se encuentra el glaciar Perito Moreno, es conocido por sus paisajes helados en constante cambio. Las ciudades de El Chaltén y El Calafate son centros para explorar Los Glaciares.",
      Frases: ["  Queria conocer Rio Gallegos","  Queria conocer Rio Gallegos"],
      Frases2: ["  Reservo en Hotel Alto Calafate","  Reservo en Hotel Alto Calafate"],
      Frases3: ["  Se marcho a investigar la causa Hotesur","  Se marcho a investigar la causa Hotesur"],
      imagen:"../../Assets/santacruz.jpg"
   },
   {
      Nombre: "Santa Fe",
      Descripcion:"  Santa Fe es una provincia agrícola en el noreste de Argentina. A lo largo de su frontera oriental fluye el río Paraná, una de las principales vías fluviales de América del Sur. Rosario, un concurrido puerto en el río, alberga el Monumento de la Bandera Nacional, que conmemora la Guerra de la Independencia del siglo XIX en Argentina. La capital, Santa Fe de la Vera Cruz, tiene un centro colonial del siglo XVII. Ambas ciudades son base para la navegación en ríos y excursiones de pesca.",
      Frases: ["  Se marcho Rosar  ","  Se marcho a conocer Reconquista  "],
      Frases2: ["  Quera ver un partido de newells de local  ","  Messi no es de alli?  "],
      Frases3: ["  Queria conoce rle monumento a la bandera  ","  Messi no es de alli?  "],
      imagen:"../../Assets/santafe.jpg"
   },
   {
      Nombre: "Santiago del Estero",
      Descripcion:"  Santiago del Estero es una ciudad argentina, capital de la provincia de Santiago del Estero y cabecera del departamento Juan Francisco Borges. Es la más antigua de las ciudades existentes del país",
      Frases: ["  Queria ver el estadio unico","Queria ver el estadio unico"],
      Frases2: ["  Decia que de ahi se veia el puente carretero","Decia que de ahi se veia el puente carretero"],
      Frases3: ["  Queria ver el Motogp","Queria ver el Motogp"],
      imagen:"../../Assets/santiago.jpg"
   },
   {
      Nombre: "Tierra del Fuego",
      Descripcion:"  Tierra del Fuego, Antártida e Islas del Atlántico Sur, en el texto de la Constitución provincial: Provincia de Tierra del Fuego, Antártida e Islas del Atlántico Sur, ​ es una de las veintitrés provincias que conforman la República Argentina.",
      Frases: ["  Dijo algo del fin del mundo","  Dijo algo del fin del mundo"],
      Frases2: ["  Se fue pasando el estrecho de Magallanes","  Se fue pasando el estrecho de Magallanes"],
      Frases3: ["  Estaba harto de todo ¡, queria ir lo mas al sur posible","  Estaba harto de todo ¡, queria ir lo mas al sur posible"],
      imagen:"../../Assets/tierradelfuego.jpg"
   },
   {
      Nombre: "Tucumán",
      Descripcion:"  Tucumán es una provincia ubicada en el noroeste de Argentina. Su capital es San Miguel de Tucumán, una ciudad histórica que fue un importante centro durante la época colonial. Tucumán es conocida como El Jardín de la República y es la provincia más pequeña de Argentina en términos de superficie.",
      Frases: ["  Dijo algo del jardin de no se que, seguro hay muchas flores pa fumar","  Dijo algo del jardin de no se que, seguro hay muchas flores pa fumar"],
      Frases2: ["  San Miguel es lindo en esta epoca","  San Miguel es lindo en esta epoca"],
      Frases3: ["  Queria comer guiso de llama en Tafi del Valle","  Queria comer guiso de llama en Tafi del Valle"],
      imagen:"../../Assets/tucuman.jpg"
   },

   ],
   Personas: ["Harry Styles", "Leonel Messi", "Lebron James"]

}