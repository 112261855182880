import React from 'react';
import styled from 'styled-components';
import ComponenteTransferencia from './componentepreguntar';
import { useState, useEffect } from "react";
import imagenrandom from '../../Assets/aunrandom.jpg'
import imagenhippie from '../../Assets/hippies.JPG'
import imagenpersonal from '../../Assets/personalaeropuerto.jpg'
import { Toolbar } from '@mui/material';
import {Provincias } from "./datos";
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Transferencias = (props) => {

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const Page = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available; /* mobile viewport bug fix */
    overflow-x: auto;
    scroll-behavior: smooth;
  `;
  
  const Grid = styled.div`
    display: flex;
    flex-wrap: nowrap;
    padding-left: calc(20vw - 160px);
  
    /* Fake padding-right */
    &::after {
      content: '';
      position: relative;
      display: block;
      flex-shrink: 0;
      width: calc(50vw - 160px);
      height: 1px;
    }
  
    > button {
      margin-right: 40px;
    }
  
    /* Hide the others cards */
    > button:not(:first-child) {
      visibility: visible; /* switch to 'visible' */
    }
  `;
  
return (
    <Page>
      <br/>

      {isMatch ? <>
       
           <ComponenteTransferencia
          zona={"preguntar a un Hippie"}
          manzana={""}
       provincia={2}
          image={imagenhippie}
          provinciaprox={props.provinciaprox}
          subirsospecha= {props.subirsospecha}
          dialogo={"hippie"}
          // image={'/Assets/IMG_7194.JPG'}
          frase={ props.provinciaact===props.provinciault ? Provincias[props.provinciaprox].Frases[(Math.floor(Math.random() * 2))] :"  No Ameo, no le vi a nadie"  }
        />  
           <ComponenteTransferencia
          zona={"Preguntar a personal del aeropuerto"}
          manzana={""}
       provincia={2}
       provinciaprox={props.provinciaprox}
          image={imagenpersonal}
          subirsospecha= {props.subirsospecha}
          dialogo={"personal"}
          frase={ props.provinciaact===props.provinciault ? Provincias[props.provinciaprox].Frases2[(Math.floor(Math.random() * 2))] :"  La verdad es que por aca no paso nadie con esas caracteristicas"   }
         // image={'/Assets/IMG_7194.JPG'}
        /> 
         <ComponenteTransferencia
          zona={"Preguntar a un random"}
          manzana={""}
       provincia={2}
       provinciaprox={props.provinciaprox}
       subirsospecha= {props.subirsospecha}
          image={imagenrandom}
          frase={ props.provinciaact===props.provinciault ? Provincias[props.provinciaprox].Frases3[(Math.floor(Math.random() * 2))] :"  Me mataste che no soy de aca, igual no creo que haya àsado aun por aca"  }

         // image={'/Assets/IMG_7194.JPG'}
        /></>:<>
        
        <Grid>
        <ComponenteTransferencia
          zona={"preguntar a un Hippie"}
          subirsospecha= {props.subirsospecha}
          manzana={""}
       provincia={2}
          image={imagenhippie}
          dialogo={"hippie"}

          // image={'/Assets/IMG_7194.JPG'}
          frase={ props.provinciaact===props.provinciault ? Provincias[props.provinciaprox].Frases[(Math.floor(Math.random() * 2))] : "  No Ameo, no le vi a nadie" }
        />  
           <ComponenteTransferencia
          zona={"Preguntar a personal del aeropuerto"}
          manzana={""}
          subirsospecha= {props.subirsospecha}
       provincia={2}
       dialogo={"personal"}
          image={imagenpersonal}
          frase={ props.provinciaact===props.provinciault ? Provincias[props.provinciaprox].Frases2[(Math.floor(Math.random() * 2))] :"  La verdad es que por aca no paso nadie con esas caracteristicas"   }

         // image={'/Assets/IMG_7194.JPG'}
        /> 
         <ComponenteTransferencia
          zona={"Preguntar a un random"}
          subirsospecha= {props.subirsospecha}
          manzana={""}
       provincia={2}
          image={imagenrandom}
          frase={ props.provinciaact===props.provinciault ? Provincias[props.provinciaprox].Frases3[(Math.floor(Math.random() * 2))] :"  Me mataste che no soy de aca, igual no creo que haya àsado aun por aca"   }
         // image={'/Assets/IMG_7194.JPG'}
        />
        </Grid>
        
        </>}
    <Toolbar />
  </Page>
/* key={lote.zona}
hexa={lote.manzana}
title={lote.parcela}
description={lote.zona} */


)  

}
    export default Transferencias