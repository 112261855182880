import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import './Ruleta.css';
import Perdiste from '../../Assets/perdiste.jpeg';
import Ganaste from '../../Assets/capybara.gif';
import iconFauna from '../../Assets/fauna y flora.png';
import iconHistoria from '../../Assets/historia.png';
import iconComidas from '../../Assets/comida.png';
import iconMitos from '../../Assets/mito.png';
import iconCorona from '../../Assets/corona.png';
import VolumeUpIcon from '@mui/icons-material/VolumeUp'; 
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Fab from '@mui/material/Fab';
import sonidoGirar from '../../Assets/girar.mp3';
import sonidoGanar from '../../Assets/ganar.mp3';
import sonidoPerder from '../../Assets/perder.mp3';
import NavigationIcon from '@mui/icons-material/Navigation';
import servicio from '../../services/servicio1'
const categorias = ['Fauna_y_Flora', 'Historia', 'Comidas_Típicas', 'Mitos_y_Leyendas', 'Corona'];
const angulos = {
  Mitos_y_Leyendas: 0,
  Comidas_Típicas: 72,
  Historia: 144,
  Fauna_y_Flora: 216,
  Corona: 288,
};

// Tus preguntas aquí

const preguntas = {
  Fauna_y_Flora: [
    { pregunta: "¿Cuál es el ave provincial de Corrientes?", opciones: ["Tero", "Carpintero", "Ñandubay"], respuesta: "Ñandubay" },
    { pregunta: "¿Qué especie animal es característica del río Paraná en Corrientes?", opciones: ["Yacaré", "Dorado", "Tucán"], respuesta: "Dorado" },
    { pregunta: "¿Qué árbol autóctono de Corrientes es famoso por sus flores rojas?", opciones: ["Ceibo", "Algarrobo", "Lapacho"], respuesta: "Ceibo" },
    { pregunta: "¿Cuál de estos animales habita en los Esteros del Iberá?", opciones: ["Puma", "Carpincho", "Cóndor"], respuesta: "Carpincho" },
    { pregunta: "¿Qué planta acuática se encuentra comúnmente en los humedales correntinos?", opciones: ["Camalote", "Eucalipto", "Ceibo"], respuesta: "Camalote" }
  ],
  Historia: [
    { pregunta: "¿En qué año fue fundada la ciudad de Corrientes?", opciones: ["1556", "1588", "1604"], respuesta: "1588" },
    { pregunta: "¿Qué pueblo indígena habitaba la zona de Corrientes antes de la llegada de los colonizadores?", opciones: ["Mapuches", "Guaraníes", "Tobas"], respuesta: "Guaraníes" },
    { pregunta: "¿Cuál fue el nombre del fundador de la ciudad de Corrientes?", opciones: ["Juan de Garay", "Hernandarias", "Juan Torres de Vera y Aragón"], respuesta: "Juan Torres de Vera y Aragón" },
    { pregunta: "¿Qué hecho histórico se conmemora cada 3 de abril en Corrientes?", opciones: ["La fundación de la ciudad", "La Batalla de Pago Largo", "La independencia provincial"], respuesta: "La fundación de la ciudad" },
    { pregunta: "¿Quién fue el gobernador correntino que participó en las guerras civiles argentinas?", opciones: ["Justo José de Urquiza", "Pedro Ferré", "José de San Martín"], respuesta: "Pedro Ferré" }
  ],
  Comidas_Típicas: [
    { pregunta: "¿Cuál es un plato típico de Corrientes a base de harina de maíz?", opciones: ["Empanada", "Mbaipy", "Guiso de lentejas"], respuesta: "Mbaipy" },
    { pregunta: "¿Qué pescado de río es común en las comidas típicas correntinas?", opciones: ["Pacú", "Merluza", "Tilapia"], respuesta: "Pacú" },
    { pregunta: "¿Qué ingrediente es esencial en el 'chipá correntino'?", opciones: ["Harina de trigo", "Harina de maíz", "Almidón de mandioca"], respuesta: "Almidón de mandioca" },
    { pregunta: "¿Qué postre tradicional es típico de Corrientes?", opciones: ["Arroz con leche", "Mamón en almíbar", "Flan"], respuesta: "Mamón en almíbar" },
    { pregunta: "¿Qué comida típica correntina incluye  harina de maíz?", opciones: ["mbaipy", "Ropa vieja", "Locro"], respuesta: "mbaipy" }
  ],
  Mitos_y_Leyendas: [
    { pregunta: "¿Cómo se llama el ser mitológico correntino que cuida los montes y las selvas?", opciones: ["Pombero", "Kurupí", "Yasy Yateré"], respuesta: "Pombero" },
    { pregunta: "¿Qué leyenda correntina habla de un espíritu que castiga a quienes dañan la naturaleza?", opciones: ["Lobizón", "El Pombero", "La Llorona"], respuesta: "El Pombero" },
    { pregunta: "¿Qué criatura mitológica correntina tiene el poder de curar enfermedades con sus huesos?", opciones: ["Yasy Yateré", "Kurupí", "Lobizón"], respuesta: "Lobizón" },
    { pregunta: "¿Cuál es el mito correntino que habla de un niño de cabellos dorados que sale al mediodía?", opciones: ["Kurupí", "Yasy Yateré", "Pombero"], respuesta: "Yasy Yateré" },
   { pregunta: "¿Qué significa mandiyu en guarani?", opciones: ["Verde", "Futbol", "Algodon"], respuesta: "Algodon" }
  ],
  Corona: [
    { pregunta: "pregunta corona", opciones:  ["quitar corona", "perdiste", "vida extra", "vida menos"], respuesta: "vida extra"  },
    //{ pregunta:  "¿Quién ganó el Torneo de pádel de ccari??", opciones:  ["Cuqui", "Pipo", "Marcelo", "Nunca hubo"], respuesta: "Marcelo" }
    // Añadir más preguntas aquí
  ]
};

// Función para obtener la imagen según la categoría
const obtenerImagenCategoria = (categoria) => {
  switch (categoria) {
    case 'Fauna_y_Flora':
      return iconFauna;
    case 'Historia':
      return iconHistoria;
    case 'Comidas_Típicas':
      return iconComidas;
    case 'Mitos_y_Leyendas':
      return iconMitos;
    case 'Corona':
      return iconCorona;
    default:
      return null;
  }
};

const Ruleta = ({ girarRuleta }) => {
  const [girando, setGirando] = useState(false);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
  const [vidas, setVidas] = useState(1);
  const [preguntasCorrectas, setPreguntasCorrectas] = useState(0);
  const [partidaTerminada, setPartidaTerminada] = useState(false);
  const [dialogAbierto, setDialogAbierto] = useState(false);
  const [mostrarImagenCategoria, setMostrarImagenCategoria] = useState(true); // Mostrar imagen 2 segundos
  const [tiempoRestante, setTiempoRestante] = useState(10); // Temporizador 8 segundos
  const [respuestaCorrecta, setRespuestaCorrecta] = useState(false);
  const [categoriasRestantes, setCategoriasRestantes] = useState(categorias);
  const [preguntaActual, setPreguntaActual] = useState(null);
  const audioGirar = new Audio(sonidoGirar);
  const audioGanar = new Audio(sonidoGanar);
  const audioPerder = new Audio(sonidoPerder);
  const [audioActivo, setAudioActivo] = useState(true);
  const [vecesjugado, setVecesjugado] = useState();
  const [mostrarGanaste, setMostrarGanaste] = useState(false);
  const [mostrarPerdiste, setMostrarPerdiste] = useState(false);

  const toggleAudio = () => setAudioActivo(!audioActivo);

  useEffect(() => {
    if (categoriasRestantes.length === 0) setCategoriasRestantes(categorias);
  }, [categoriasRestantes]);
  useEffect(() => {
    traer();
  }, []);
  const traer = async () => {
    try {
      const historial = await servicio.traercantidades();
      setVecesjugado(historial)
    } catch (error) {
      console.error(error);
    }
  };
  const sumarjugada = async () => {
    try {
      const historial = await servicio.sumarjugada();
      traer(historial)
    } catch (error) {
      console.error(error);
    }
  };
  // Reiniciar el temporizador cuando cambia la pregunta
  useEffect(() => {
   
    let timer;
    if (dialogAbierto && !mostrarImagenCategoria) {
      timer = setInterval(() => {
        setTiempoRestante((prev) => prev - 1);
        if (tiempoRestante === 0) {
          perderVida();
          clearInterval(timer);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [preguntaActual, dialogAbierto, mostrarImagenCategoria, tiempoRestante]);

  const manejarGiro = () => {
    if (partidaTerminada) return;

    setGirando(true);
    if (audioActivo) audioGirar.play();

    document.querySelector('.ruleta').style.transition = 'none';
    document.querySelector('.ruleta').style.transform = `rotate(0deg)`;

    setTimeout(() => {
      document.querySelector('.ruleta').style.transition = 'transform 3s ease-out';
      const categoriaAleatoria = seleccionarCategoriaAleatoria();
      const angulo = angulos[categoriaAleatoria];
      document.querySelector('.ruleta').style.transform = `rotate(${angulo + 3600 - 90}deg)`;

      setTimeout(() => {
        setGirando(false);
        setCategoriaSeleccionada(categoriaAleatoria);
        const preguntasCategoria = preguntas[categoriaAleatoria];
        const preguntaAleatoria = preguntasCategoria[Math.floor(Math.random() * preguntasCategoria.length)];
        setPreguntaActual(preguntaAleatoria);
        setDialogAbierto(true);
        setMostrarImagenCategoria(true); // Mostrar imagen al abrir el dialog
        setTimeout(() => setMostrarImagenCategoria(false), 2000); // Después de 2 segundos mostrar la pregunta
        setCategoriasRestantes(categoriasRestantes.filter(cat => cat !== categoriaAleatoria));
      }, 3000);
    }, 50);
  };

  const seleccionarCategoriaAleatoria = () => {
    const categoriasConProbabilidad = categoriasRestantes.reduce((result, categoria) => {
      if (categoria === 'Corona') {
        result.push(categoria);
      } else {
        result.push(categoria, categoria, categoria);
      }
      return result;
    }, []);
    
    return categoriasConProbabilidad[Math.floor(Math.random() * categoriasConProbabilidad.length)];
  };

  const manejarRespuesta = (respuesta) => {
    if (respuesta === preguntaActual.respuesta) {
      setPreguntasCorrectas(preguntasCorrectas + 1);
      setRespuestaCorrecta(true);
      if (audioActivo) audioGanar.play();
  
      if (preguntasCorrectas + 1 === 5) {
        setPartidaTerminada(true);
        setMostrarGanaste(true); // Mostrar GIF
        sumarjugada();
      }
  
      setTimeout(() => {
        setRespuestaCorrecta(false);
        setDialogAbierto(false);
      }, 2000);
    } else {
      perderVida();
    }
    setTiempoRestante(10);
  };
  

  const perderVida = () => {
    setVidas(vidas - 1);
    setDialogAbierto(false);
    if (audioActivo) audioPerder.play();

    if (vidas - 1 === 0) {
      setPartidaTerminada(true);
      setMostrarPerdiste(true); // Activar la imagen de "Perdiste"
      sumarjugada()
    }
  };

  const reiniciarPartida = () => {
    setVidas(1);
    setMostrarPerdiste(false)
    setPreguntasCorrectas(0);
    setPartidaTerminada(false);
    setDialogAbierto(false);
    setCategoriasRestantes(categorias);
    setTiempoRestante(8); // Reiniciar el temporizador
  };
  const manejarCorona = () => {
    
    setVidas(vidas + 1);
    alert('Vida sumada!')
     setDialogAbierto(false);}
     const manejarCoronaNosumar = () => {
    alert('No sumaste vida')
       setDialogAbierto(false);}
  return (
    <>
    {vecesjugado && <>
    Veces jugadas:{vecesjugado.punt}</>
    
    }
      <div className="sonido-control">
        <Button onClick={toggleAudio}>
          {audioActivo ? <VolumeUpIcon style={{ fontSize: 50 }} /> : <VolumeOffIcon style={{ fontSize: 50 }} />}
        </Button>
      </div>
      <div className="ruleta-container">
        <div className={`ruleta ${girando ? 'girando' : ''}`}>
          <img src={iconFauna} alt="Fauna y Flora" className="icono fauna_y_flora" />
          <img src={iconHistoria} alt="Historia" className="icono historia" />
          <img src={iconComidas} alt="Comidas Típicas" className="icono comidas_tipicas" />
          <img src={iconMitos} alt="Mitos y Leyendas" className="icono mitos_y_leyendas" />
          <img src={iconCorona} alt="Corona" className="icono corona" />
        </div>
        <div className="marcador" />
        <Button variant="contained" color="primary" onClick={manejarGiro} disabled={girando || partidaTerminada}>
          {girando ? 'Girando...' : 'Girar Ruleta'}
        </Button>
        <div className="status">
          <h2 style={{ color: 'green' }}>Vidas: {vidas}</h2>
          <h3>Preguntas correctas: {preguntasCorrectas}/5</h3>
        </div>

        {partidaTerminada && (
  <>
    {mostrarGanaste ? (
      <img src={Ganaste} alt="Ganaste" />
    ) : (<>
      {mostrarPerdiste ? <><h2>Perdiste</h2><br/><img src={Perdiste} style={{ width: "200px"}} alt="Perdiste" /></>:<></>} 


 </>   )}
    <Button variant="contained" color="secondary" onClick={reiniciarPartida}>
      Reiniciar Partida
    </Button>
  </>
)}

        <Dialog open={dialogAbierto} onClose={() => {}} disableEscapeKeyDown>
          <DialogTitle>{`Pregunta de ${categoriaSeleccionada}`}</DialogTitle>
          <DialogContent>
            {mostrarImagenCategoria ? (
              <img src={obtenerImagenCategoria(categoriaSeleccionada)} alt={categoriaSeleccionada} style={{ width: '200px' }} />
            ) : (
              <>
                <h3>{preguntaActual ? preguntaActual.pregunta : ''}</h3>
                <h5> <b>Tiempo restante: {tiempoRestante}s</b></h5>
              </>
            )}
          </DialogContent>
        
<DialogActions style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
 
    <>
      {!mostrarImagenCategoria &&
        (categoriaSeleccionada === "Corona" ? (
          <>
        
          <Button 
            variant="contained" 
            color="primary" 
            onClick={manejarCorona}
          >
           Sumar Vida
          </Button>
      
          </>
        ) : (
          // Si no es "Corona", muestra las opciones de respuesta como antes
          preguntaActual?.opciones.map((opcion, index) => (
            <Button 
              variant="contained" 
              className="boton-respuesta" 
              color="primary" 
              key={index} 
              onClick={() => manejarRespuesta(opcion)}
            >
              {opcion}
            </Button>
          ))
        ))
      }
    </>

</DialogActions>

        </Dialog>
        <Dialog open={mostrarGanaste} onClose={() => setMostrarGanaste(false)}>
      <DialogTitle>¡Ganaste!</DialogTitle>
      <DialogContent>
        {/* Aquí va tu GIF o imagen de "Ganaste" */}
        <img src={Ganaste} alt="Ganaste" style={{ width: '100%', maxHeight: '300px' }} />
        <p>Pasa a buscar tu premio en el estand.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setMostrarGanaste(false)} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
        {respuestaCorrecta && <div className="animacion-acierto">¡Correcto!</div>}
      </div>
    </>
  );
};

export default Ruleta;
