import { useState, useEffect, useRef } from "react";


import { Paper, CircularProgress, Typography, Box, TextField, InputLabel, Card, CardActions } from '@mui/material';
import {
    useMediaQuery,
    useTheme,
} from "@mui/material";
import NativeSelect from '@mui/material/NativeSelect';
import { useNavigate } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import * as React from 'react';

import Logoesme from '../../Assets/votacion/tit1.jpeg';
// import Logocuqui from '../../Assets/votacion/fani.jpeg';
// import Logoccari from '../../Assets/votacion/fani.jpeg';
import styled from 'styled-components';

import { height } from "@mui/system";

const styles2 = {
    paperr: {
        cursor: 'pointer',
        background: '#ffffff',
        color: '#bdbdbd',
        border: '1px dashed #ccc',
        padding: 10,
        width: '90vw',
       // maxWidth: 4000,
       // margin: '20px auto', // Margen superior e inferior de 20px, centrado horizontalmente
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        alignItems: 'start',
        padding: 5,
        transform: 'scale(0.90)', /* Esto escalará el contenido al 50% del tamaño original */
        // transform-origin: top left; 

    },





};


const StyledParagraph = styled.p`
  font-family: 'Montserrat', sans-serif;
`;

const Estracto = () => {
    const [cargando, setCargando] = useState(false);
    const [loading, setLoading] = useState(false);
    const [existe, setExiste] = useState([]);
    const [inscrip, setInscrip] = useState(['']);
    const paperRef = useRef(null);
    const [cohortes, setCohorte] = useState();
    const [activo, setActivo] = useState(false);
    const theme = useTheme();
    const [zoomLevel, setZoomLevel] = useState(0.5);
    const navigate = useNavigate();

    const getClients = async (e) => {
        if (e != '') {
   
        }


    };
  
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
 
    const islogo = {
        width: "40%",
        height: "40%",
        margin: 0,
        padding: 0,
        display: "flex",

    };
    const islogoc = {
        width: "50%",
        height: "50%",
        margin: 0,
        padding: 20,
        display: "flex",
    }
    const islogo2 = {
        width: "50%",
        height: "50%",
        margin: 0,
        padding: 0,
        display: "flex",

    };
    const islogoc2 = {
        width: "100%",
        height: "100%",
        margin: 0,
       // padding: 20,
        display: "flex",
    }


    const handleChange = (e) => {
        setInscrip({ ...inscrip, [e.target.name]: e.target.value });
        console.log(inscrip)
    };
    const handleChange1 = (e) => {
        setInscrip({ ...inscrip, [e.target.name]: e.target.value });
        getClients(e.target.value)
    };

  
    return (
        <>
           
                    <Paper

                        className="aparecer-desde-abajo"
                        style={styles2.paperr}
                    >
                        <Box className="logo-container">
                            <img style={islogoc2} className="islogoc" src={Logoesme} alt="logo" />
                        
                        </Box>
               
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h7" component="div" color="black">
                                <StyledParagraph>
Fecha de votacion finalizada!
                                    <br />

                                  ¡Gracias por sumarte a los BLAZE AWARDS CORRIENTES!, Actualmente se encuentran cerradas las votaciones, pronto conoceras a los ganadores.<br/>Te esperamos con el evento de gala  el 14/12 en el anfiteatro Jose Hernandez. Invitamos a ver mas informacion en www.cuquicalvano.com!
                                </StyledParagraph>
                            </Typography>
                        </Box>


                    

                    </Paper>
          
          
        </>
    );
};

export default Estracto;
