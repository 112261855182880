// src/Platform.js
import React from 'react';
import  dinosaurImg from '../Assets/cuqui1.png';

const Platform = ({ x, y, width, height }) => (
  <div style={{
    position: 'absolute',
    left: `${x}px`,
    bottom: `${y}px`,
    width: `${width}px`,
    height: `${height}px`,
    backgroundImage: {dinosaurImg},
    backgroundSize: 'cover'
  }} />
);

export default Platform;