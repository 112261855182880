import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

const TypingText = ({ text }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setDisplayText((prevText) => {
        if (currentIndex < text.length) {
          return prevText + text[currentIndex];
        } else {
          clearInterval(intervalId);
          return prevText;
        }
      });
      currentIndex += 1;
    }, 30); // Ajusta la velocidad según tus preferencias

    return () => clearInterval(intervalId);
  }, [text]);

  return (
    <Typography>
      {displayText}
    </Typography>
  );
};

export default TypingText;
