import Menu2 from '../pages/menu';
import Provincia from '../pages/mapaprovincia';
import Fana from '../pages/fanargentino';
import Dino from '../pages/dino';
import Mario from '../pages/game2'
import Globe from '../pages/globe'
import Prueba from '../pages/prueba'
import Preguntados from '../pages/preguntados'
import Votacion from '../pages/votacion'
import Vistavotacion from '../pages/vistavotacion'
import Votantes from '../pages/votantes'
import Resultadosocultos from '../pages/secreta'
const Rutas = [

	{ path: '/', element: <Vistavotacion/> },
	{ path: '/menu', element: <Menu2/> },
	{ path: '/provincia', element: <Provincia/> },
	{ path: '/fan-argentino', element: <Fana/> },
	{ path: '/botella', element: <Dino/> },
	{ path: '/mario', element: <Mario/> },
	{ path: '/charla', element: <Globe/> },
	{ path: '/prueba', element: <Prueba/> },
	{ path: '/preguntados', element: <Preguntados/> },
	{ path: '/votacion', element: <Votacion/> },
	{ path: '/votantess', element: <Votantes/> },
	{ path: '/resultados', element: <Resultadosocultos/> },
	
	
	
	
	
];


export default Rutas;