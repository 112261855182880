


import Game from '../../components/Game.js'
import React, { useEffect, useState } from "react";
import NAv from '../../components/fana/Navbar'
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function Paginas() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));


   
            return (
                <>
               <Game/>
                </>
           
            );
        
        }