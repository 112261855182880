import React, { useState } from 'react';
import Ruleta from './Ruleta'; // Asegúrate de que la ruta sea correcta
import { Button } from '@mui/material'; // Importar el componente Button de Material UI

const preguntasPorCategoria = {
  Deportes: [
    {
      pregunta: "¿Quién ganó el Torneo de pádel de ccari?",
      opciones: ["Cuqui", "Pipo", "Marcelo", "Nunca hubo"],
      respuestaCorrecta: "Marcelo",
    },
    {
      pregunta: "¿Cuál es el deporte más popular en Estados Unidos?",
      opciones: ["Waterpolo", "Béisbol", "Baloncesto", "Hockey"],
      respuestaCorrecta: "Baloncesto",
    },
  ],
  Ciencia: [
    {
      pregunta: "¿Qué es programar?",
      opciones: ["Calcular números", "Dar instrucciones a una PC", "Dormir", "No tener vida"],
      respuestaCorrecta: "Dar instrucciones a una PC",
    },
    {
      pregunta: "¿Qué elemento químico tiene el símbolo O?",
      opciones: ["Osmosis", "Oreo", "Oro", "Oxígeno"],
      respuestaCorrecta: "Oxígeno",
    },
  ],
  Historia: [
    {
      pregunta: "¿En qué año cayó el muro de Berlín?",
      opciones: ["1985", "1989", "1991", "Anteayer"],
      respuestaCorrecta: "1989",
    },
    {
      pregunta: "¿Quién fue el primer presidente de Argentina?",
      opciones: ["George Washington", "Alberdi", "Javier Milei", "James Madison"],
      respuestaCorrecta: "Javier Milei",
    },
  ],
  Arte: [
    {
      pregunta: "¿Quién pintó la Mona Lisa?",
      opciones: ["Leonardo da Vinci", "Vincent van Gogh", "Pablo Picasso", "Pintacaritas"],
      respuestaCorrecta: "Leonardo da Vinci",
    },
    {
      pregunta: "¿Cuál de estos es un temazo?",
      opciones: ["La motito de Carlitos", "Hacelo por mí", "Arro ro", "Tu turrito"],
      respuestaCorrecta: "La motito de Carlitos",
    },
  ],
};

const AppPrincipal = () => {
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
  const [preguntaActual, setPreguntaActual] = useState(null);
  const [respuestaSeleccionada, setRespuestaSeleccionada] = useState(null);
  const [resultado, setResultado] = useState(null);

  // Maneja la categoría seleccionada desde la ruleta
  const manejarCategoria = (categoria) => {
    setCategoriaSeleccionada(categoria);
    setPreguntaActual(null); // Reinicia la pregunta actual cuando cambia la categoría
    setRespuestaSeleccionada(null); // Reinicia la respuesta seleccionada
    setResultado(null); // Reinicia el resultado

    // Selecciona una pregunta aleatoria de la categoría seleccionada
    const preguntas = preguntasPorCategoria[categoria];
    if (preguntas) {
      const preguntaAleatoria = preguntas[Math.floor(Math.random() * preguntas.length)];
      setPreguntaActual(preguntaAleatoria);
    }
  };

  // Maneja la selección de respuesta
  const manejarRespuesta = (respuesta) => {
    setRespuestaSeleccionada(respuesta);
    if (preguntaActual) {
      const esCorrecta = respuesta === preguntaActual.respuestaCorrecta;
      setResultado(esCorrecta ? '¡Correcto!' : 'Incorrecto. La respuesta correcta es: ' + preguntaActual.respuestaCorrecta);
    }
  };

  return (
    <div>
      <Ruleta girarRuleta={manejarCategoria} />
      <div>
        {categoriaSeleccionada && (
          <div>
            <h1>Categoría Seleccionada: {categoriaSeleccionada}</h1>
            {preguntaActual && (
              <div>
                <h2>Pregunta: {preguntaActual.pregunta}</h2>
                <div>
                  {preguntaActual.opciones.map((opcion, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      onClick={() => manejarRespuesta(opcion)}
                    >
                      {opcion}
                    </Button>
                  ))}
                </div>
                {respuestaSeleccionada && (
                  <div>
                    <h3>{resultado}</h3>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppPrincipal;
