// src/Coin.js
import React from 'react';
import  dinosaurImg from '../Assets/cuqui1.png';
const Coin = ({ x, y }) => (
  <div style={{
    position: 'absolute',
    left: `${x}px`,
    bottom: `${y}px`,
    width: '20px',
    height: '20px',
    backgroundImage: {dinosaurImg},
    backgroundSize: 'cover'
  }} />
);

export default Coin;