import Box from '@mui/material/Box';
import {Personas } from "./datos";
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';



import React, { useEffect, useState, Fragment } from "react";


export default function SelectTextFields(props) {
  const [open, setOpen] = React.useState(false);
  //const usuario  = useUser().userContext

  const [seleccion, seSeleccion] = useState([''])
  



  const handleClickOpen = () => {
    setOpen(true);
    
  };

  const handleClose = () => {
    setOpen(false);
    props.comenzar()
  };


  const handleChange = (e) => {
 
    seSeleccion({ ...seleccion, [e.target.name]: e.target.value })
    console.log(seleccion)
  }
  ////
  
  const [currency, setCurrency] = React.useState('EUR');

  /*   const handleChange = (event) => {
      setCurrency(event.target.value);
    }; */


  return (

    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Button variant="outlined" onClick={handleClickOpen}>
       Comenzar el juego!
      </Button>
      <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Bienvenido/a</DialogTitle>
        <DialogContent>
          <div>
      Vamos a ponernos en contexto:
      Eres un fan de {Personas[0]}. Recientemente te has enterado que ha venido a Argentina en sus vacaciones.
      Como todo buen fan (y con plata) quieres dar con esa persona y para ello tendras que seguirle por todo el pais hasta coincidir.
      Tendras el menu para preguntar si alguien lo ha visto. Segun esas pistas podras viajar por toda la Argentina.
      Tene en cuenta que mientras mas viajes y preguntes, mas va a sospechar la policia de tu persona. Arriba tendras un rating de sospecha.
      Mientras menos preguntes menos sospecharan, pero sin preguntas no hay pistas. 
           
    
        </div>
      </DialogContent>
       <Button onClick={handleClose} variant='contained' >
               Listo
            </Button>
    </Dialog>
    </Box >




  );
}