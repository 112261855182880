import Elemento1 from '../../components/preguntados/principal';
import React from "react";
import pollito from '../../Assets/pollito.jpg'; // Importa la imagen

export default function Paginas() {
  return (
    <>
      <div style={{ 
        backgroundImage: `url(${pollito})`,  // Establece la imagen como fondo
        backgroundSize: 'cover',             // Ajusta el tamaño del fondo para cubrir el área
        backgroundPosition: 'center',        // Centra la imagen de fondo
        height: '100vh'                      // Ajusta el tamaño de la altura
      }}>
        <Elemento1 />
      </div>
    </>
  );
}
