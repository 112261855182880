import React, { useEffect } from "react";
import Formulario from "../../components/formulariocerrado/componentecerrado";

export default function Paginas() {
  useEffect(() => {
    const handlePopState = () => {
      const confirmExit = window.confirm(
        "¡Atención! Sales de la página, para volver a votar tendrás que volver a registrarte. Los votos que ya has hecho se guardarán, no te preocupes."
      );
      if (!confirmExit) {
        // Volver al estado anterior si el usuario cancela
        window.history.pushState(null, "", window.location.href);
      }
    };

    const handleBeforeUnload = (event) => {
      const message =
        "¡Atención! Si cierras o recargas la página, para volver a votar tendrás que volver a registrarte. Los votos que ya has hecho se guardarán, no te preocupes.";
      event.preventDefault();
      // Esta línea muestra el mensaje genérico del navegador.
      event.returnValue = message; 
      return message; // Necesario para algunos navegadores.
    };

    // Añadir los eventos
    window.addEventListener("popstate", handlePopState); // Para botón de atrás
    window.addEventListener("beforeunload", handleBeforeUnload); // Para cierre o recarga

    // Empuja un nuevo estado para controlar el botón "atrás"
    window.history.pushState(null, "", window.location.href);

    return () => {
      // Limpiar eventos
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      <br />
      <Formulario />
    </div>
  );
}
