import React, { useState, useEffect } from 'react';
import Dinosaur from './Dinosaur';
import Obstacle from './Obstacle';

const Game = () => {
  const [dinoY, setDinoY] = useState(0);
  const [dinoHeight, setDinoHeight] = useState(40);
  const [obstacleX, setObstacleX] = useState(300);
  const [obstacleY, setObstacleY] = useState(0); // Altura del obstáculo aéreo
  const [isJumping, setIsJumping] = useState(false);
  const [isDucking, setIsDucking] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [score, setScore] = useState(0);
  const [speed, setSpeed] = useState(5);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 32 && !isJumping && !isDucking) { // Barra espaciadora para saltar
        setIsJumping(true);
      }
      if (event.keyCode === 40 && !isDucking && !isJumping) { // Tecla de abajo para agacharse
        setIsDucking(true);
        setDinoHeight(20); // Reducir la altura del dinosaurio
      }
    };

    const handleKeyUp = (event) => {
      if (event.keyCode === 40) { // Soltar la tecla de abajo
        setIsDucking(false);
        setDinoHeight(40);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isJumping, isDucking]);

  useEffect(() => {
    let jumpInterval;
    if (isJumping) {
      jumpInterval = setInterval(() => {
        setDinoY((prevY) => {
          if (prevY >= 100) {
            setIsJumping(false);
            return prevY;
          }
          return prevY + 5;
        });
      }, 20);
    } else if (dinoY > 0) {
      jumpInterval = setInterval(() => {
        setDinoY((prevY) => (prevY <= 0 ? 0 : prevY - 5));
      }, 20);
    }

    return () => {
      clearInterval(jumpInterval);
    };
  }, [isJumping, dinoY]);

  useEffect(() => {
    let obstacleTimer;
    if (isGameStarted) {
      obstacleTimer = setInterval(() => {
        setObstacleX((prevX) => {
          if (prevX <= 0) {
            setScore((score) => score + 1); // Incrementar el score correctamente
            if ((score + 1) % 10 === 0) {
              setSpeed((prevSpeed) => prevSpeed + 1);
            }
            setObstacleY(Math.random() < 0.5 ? 0 : 80); // Alternar entre obstáculo en el suelo y aéreo
            return 300;
          }
          return prevX - speed;
        });

        // Detección de colisión
        if (obstacleX <= 70 && obstacleX >= 30) {
          if (obstacleY === 0 && dinoY < dinoHeight) { // Colisión con obstáculo en el suelo
            setIsGameOver(true);
            clearInterval(obstacleTimer);
          } else if (obstacleY === 80 && (dinoY < 50 || (dinoY > 30 && dinoY + dinoHeight > 80))) { // Colisión con obstáculo aéreo
            setIsGameOver(true);
            clearInterval(obstacleTimer);
          }
        }
      }, 20);
    }

    return () => {
      clearInterval(obstacleTimer);
    };
  }, [isGameStarted, obstacleX, dinoY, dinoHeight, speed, score, obstacleY]);

  const handleStart = () => {
    setIsGameStarted(true);
    setIsGameOver(false);
    setDinoY(0);
    setObstacleX(300);
    setScore(0);
    setSpeed(5);
    setObstacleY(0);
  };

  const handleRestart = () => {
    setIsGameStarted(true);
    setIsGameOver(false);
    setDinoY(0);
    setObstacleX(300);
    setScore(0);
    setSpeed(5);
    setObstacleY(0);
  };

  if (isGameOver) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1>Game Over</h1>
        <p>Score: {score}</p>
        <button onClick={handleRestart} style={buttonStyle}>Restart Game</button>
      </div>
    );
  }

  if (!isGameStarted) {
    return (
      <div style={startButtonContainerStyle}>
        <button onClick={handleStart} style={buttonStyle}>Start Game</button>
      </div>
    );
  }

  return (
    <div
      onTouchStart={() => setIsJumping(true)}
      style={{
        position: 'relative',
        width: '100%',
        height: '200px',
        backgroundColor: 'lightblue',
        overflow: 'hidden'
      }}
    >
      <Dinosaur y={dinoY} height={dinoHeight} />
      <Obstacle x={obstacleX} y={obstacleY} />
      <div style={{ position: 'absolute', top: '10px', left: '10px', fontSize: '20px' }}>
        Score: {score}
      </div>
    </div>
  );
};

const startButtonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: 'lightblue',
};

const buttonStyle = {
  padding: '20px',
  fontSize: '20px',
  cursor: 'pointer',
};

export default Game;
