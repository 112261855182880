import React, { useState, useEffect } from "react";
import servicio from '../../services/servicio1';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const TablaVotantes = () => {
  const [votantes, setVotantes] = useState([]);

  useEffect(() => {
    const fetchVotantes = async () => {
      try {
        const datos = await servicio.traervotantes();
        console.log(datos)
        setVotantes(datos);
      } catch (error) {
        console.error("Error al traer los votantes:", error);
      }
    };

    fetchVotantes();
  }, []);

  return (<>
  { votantes ? <>Cantidad de votantes: {votantes.length}</> :<></>}
    <TableContainer component={Paper} style={{ margin: "20px", overflowX: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Apellido</TableCell>
            <TableCell>dni</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Votos</TableCell>
     
           {/*  <TableCell>Votantes</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {votantes.map((votante, index) => (
            <TableRow key={index}>
              <TableCell>{votante.name}</TableCell>
              <TableCell>{votante.lastname}</TableCell>
              <TableCell>{votante.dni}</TableCell>
              <TableCell>{votante.punt}</TableCell>
              <TableCell>{votante.hora}</TableCell>
              <TableCell>{votante.votos}</TableCell>
           {/*    <TableCell>{votante.votantes}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};

export default TablaVotantes;
