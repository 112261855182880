// DialogComponent.js
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";

const DialogComponent = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [nivel, setNivel] = useState(false);

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));


  const getClients = async () => {

    const loggedUserJSON = window.localStorage.getItem('loggedNoteAppUser')

    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON)
      setNivel(user.nivel)


    }
  }

  const cerrar = () => {

    props.verificar()
    setOpen(false);
  };/////

  // Función para abrir el diálogo
  const openDialog = () => {
    console.log(props.p)
    setOpen(true);
    getClients()
  };

  // Función para cerrar el diálogo
  const closeDialog = () => {
    props.verificar()
    setOpen(false);

  };

  // Permite al componente padre llamar a openDialog desde el ref
  useImperativeHandle(ref, () => ({
    openDialog,
    closeDialog, // También puedes exponer la función closeDialog si es necesario
  }), []); // Asegura que esto se ejecute solo una vez

  return (
    <>

      <Dialog open={open} onClose={closeDialog} maxWidth="sm" 
        PaperProps={{
          style: {
            position: 'fixed',
            top: '20%',
            left: '20%',
            transform:"scale(0.5)",
          },
        }}>
        <DialogTitle>La seleccion es {props.info}</DialogTitle>
    
          <DialogContent  >

            {props.info === props.objetivo ? <>Correcto</> : <>Incorrecto</>}
          </DialogContent>
 
        {props.info === props.objetivo ? <><Button sx={{ align: "right" }} color='success' onClick={cerrar} variant='contained' >
          cerrar
        </Button></> : <><Button sx={{ align: "right" }} color='error' onClick={cerrar} variant='contained' >
          cerrar
        </Button></>}

      </Dialog>


    </>

  );
});

export default DialogComponent;
