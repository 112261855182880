import React from 'react';
import obstacleImg from '../Assets/obstacle.png';

const Obstacle = ({ x }) => {
  return (
    <div style={{
      position: 'absolute',
      bottom: '0px',
      left: `${x}px`,
      width: '40px',
      height: '40px',
      backgroundImage: `url(${obstacleImg})`,
      backgroundSize: 'cover'
    }}></div>
  );
};

export default Obstacle;

