import axios from "axios"
const url =require ('./url')


const baseUrl = url.database

/* 



const probar= async  () => {
  const headers = new Headers()
headers.append("Content-Type", "application/json")

const body = {
  "test": "holaa"
}

const options = {
  method: "POST",
  headers,
  mode: "cors",
  body: JSON.stringify(body),
}



console.log(options)
   
  const {data } = await axios.post("https://eo8dw963278aa7.m.pipedream.net",options)
  console.log(data)
return data}   */
const enviardatosvoto= async  (datos) => {

   
 const {data } = await axios.post(baseUrl+"tareas/enviardatosvoto",datos)
 
return data
} 


const enviar= async  (datos) => {

   
     const {data } = await axios.post(baseUrl+"tareas/guardar",datos)
    
  return data
 }  


 const traer= async  (datos) => {


   const {data } = await axios.get(baseUrl+"tareas/rk",datos)

return data
}  
const traercantidades = async (id) => {
  
  // const data = await axios.post('http://localhost:4000/signupp', datos)
    const {data} = await axios.get(baseUrl+'traercantidades/')
return data
     
}


const traervotantes = async (id) => {
  
  // const data = await axios.post('http://localhost:4000/signupp', datos)
    const {data} = await axios.get(baseUrl+'tareas/traervotantes/')
return data
     
}
const sumarjugada = async (id) => {
  
  // const data = await axios.post('http://localhost:4000/signupp', datos)
    const {data} = await axios.get(baseUrl+'sumarjugada/')
return data
     
}


const traervotos = async (id) => {
  
  // const data = await axios.post('http://localhost:4000/signupp', datos)
    const {data} = await axios.get(baseUrl+'traervotos/')
return data
     
}

const sumarvoto = async (id) => {
  
  // const data = await axios.post('http://localhost:4000/signupp', datos)
    const {data} = await axios.post(baseUrl+'sumarvoto/',id)
return data
     
}

 export default { traervotantes,enviardatosvoto,sumarvoto,traervotos,sumarjugada,traercantidades,traer,enviar}