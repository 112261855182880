import { useState, useEffect } from "react";
import Imagen from "../../Assets/buenosaires.jpg"
import { useNavigate } from "react-router-dom";
import {Provincias } from "./datos";
import * as React from 'react';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import { Button } from "@mui/material";
import Efectodeltecto from "./efectodeltexto"
//import overbookingData from "./overbooking";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const Lotes = (props) => {
    //configuracion de Hooks
    const [imagen, setImagen] = useState(['../../Assets/buenosaires.jpg','../../Assets/catamarca.jpg','../../Assets/chaco.jpg',
    '../../Assets/chubut.jpg','../../Assets/cordoba.jpg','../../Assets/corrientes.jpg','../../Assets/entrerios.jpg',
    '../../Assets/formosa.jpg','../../Assets/jujuy.jpg','../../Assets/lapampa.jpg',
    '../../Assets/larioja.jpg','../../Assets/mendoza.jpg','../../Assets/misiones.jpg','../../Assets/neuquen.jpg',
    '../../Assets/rionegro.jpg','../../Assets/salta.jpg',
    '../../Assets/sanjuan.jpg','../../Assets/sanluis.jpg','../../Assets/santacruz.jpg',
    "../../Assets/santafe.jpg","../../Assets/santiago.jpg","../../Assets/tierradelfuego.jpg","../../Assets/tucuman.jpg"]);
    const [isLogo, setISlogo] = useState([])
    const navigate = useNavigate();
  
   //const imagentexto=require(texto.toString)

    useEffect(() => {
      traer()
  }, [])

  const traer = async ()=>{
    // await servicio1.probar(formData)
const pop ='../../Assets/buenosaires.jpg'
console.log(props.provinciaact)
if(props.provinciaact==0){
 setISlogo({ width: "70%"})
  setImagen(require('../../Assets/buenosaires.jpg'))
}else if (props.provinciaact==1){
 setISlogo({ width: "70%"})
  setImagen(require('../../Assets/catamarca.jpg'))
    }else if (props.provinciaact==2){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/chaco.jpg'))
    }else if (props.provinciaact==3){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/chubut.jpg'))
    }else if (props.provinciaact==4){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/cordoba.jpg'))
    }else if (props.provinciaact==5){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/corrientes.jpg'))
    }else if (props.provinciaact==6){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/entrerios.jpg'))
    }else if (props.provinciaact==7){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/formosa.jpg'))
    }else if (props.provinciaact==8){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/jujuy.jpg'))
    }else if (props.provinciaact==9){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/lapampa.jpg'))
    }else if (props.provinciaact==10){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/larioja.webp'))
    }else if (props.provinciaact==11){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/mendoza.jpg'))
    }else if (props.provinciaact==12){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/misiones.jpg'))
    }else if (props.provinciaact==13){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/neuquen.jpg'))
    }else if (props.provinciaact==14){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/rionegro.jpg'))
    }else if (props.provinciaact==15){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/salta.jpg'))
    }else if (props.provinciaact==16){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/sanjuan.jpg'))
    }else if (props.provinciaact==17){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/sanluis.jpg'))
    }else if (props.provinciaact==18){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/santacruz.jpg'))
    }else if (props.provinciaact==19){
     setISlogo({ width: "70%"})
      setImagen(require( "../../Assets/santafe.jpg"))
    }else if (props.provinciaact==20){
     setISlogo({ width: "70%"})
      setImagen(require("../../Assets/santiago.jpg"))
    }else if (props.provinciaact==21){
     setISlogo({ width: "70%"})
      setImagen(require("../../Assets/tierradelfuego.jpg"))
    }else if (props.provinciaact==22){
     setISlogo({ width: "70%"})
      setImagen(require('../../Assets/santacruz.jpg'))
    }else{
      console.log(props.provinciaact)
     setISlogo({ width: "70%"})
      setImagen(require("../../Assets/tucuman.jpg"))
    }



   }
    
// renderiza la data table
return (
    <>
      <br/>
   <br/> <br/>
   {Provincias[props.provinciaact] ? <>

    Estas en {Provincias[props.provinciaact].Nombre}<br/>
   {imagen && imagen!= "nada" &&  <img src={imagen} style={isLogo}  alt="Imagen no encontrada" /> }<br/>
<Efectodeltecto text={Provincias[props.provinciaact].Descripcion}/>
   <br/>
    <b>Tu objetivo fue visto por ultima vez en  {Provincias[props.provinciault].Nombre}</b></>:<></>}

   
   
    </>
)
}

export default Lotes;