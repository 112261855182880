


import Elemento1 from '../../components/charla'
import Elemento2 from '../../components/charla2'
import Elemento3 from '../../components/charla3'
//import Elemento4 from '../../components/charla'

import React, { useEffect, useState } from "react";


export default function Paginas() {
  


   
            return (
                <>
           
              <div  >
     
                <Elemento1/>
               <Elemento2/>
                <Elemento3/>  {/*
                <Elemento4/> */}
             
                
                
                </div>
              
     
                
          
                </>
           
            );
        
        }