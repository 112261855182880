import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Efectodeltecto from "./efectodeltexto"


import React, { useEffect, useState, Fragment } from "react";


export default function SelectTextFields(props) {
  const [open, setOpen] = React.useState(false);
  //const usuario  = useUser().userContext

  const [imagen, setImagen] = useState()



  const handleClickOpen = () => {
    {if (props.dialogo=="hippie"){
      setImagen(require("../../Assets/dialogohippie.jpg"))
    }else if (props.dialogo=="personal"){
      setImagen(require("../../Assets/dialogopersonal.jpg"))
    }else {
      setImagen(require("../../Assets/dialogorandom.jpg"))
    }}
   
    setOpen(true);

    console.log(props.dialogo)
  };

  const handleClose = () => { 
     props.subirsospecha(1)
    setOpen(false);
  
  };



  ////
  

  return (

    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Button variant="outlined" onClick={handleClickOpen}>

       {props.Nombreboton}
      </Button>
      <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{props.titulo}</DialogTitle>
      
        <DialogContent>
          <div>
           
          <Efectodeltecto text={props.contenido}/>
          {imagen &&   <img src={imagen} style={{ width: "500px"}}/>}
         
    
        </div>
      </DialogContent>
       <Button onClick={handleClose} variant='contained' >
               Listo
            </Button>
    </Dialog>
    </Box >




  );
}