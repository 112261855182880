// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';

const gridSize = 5;

const App = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [commands, setCommands] = useState('');
  const [level, setLevel] = useState(1);
  const [message, setMessage] = useState('');

  const getObstacles = (level) => {
    switch (level) {
      case 2:
        return [{ x: 2, y: 2 }, { x: 3, y: 1 }];
      case 3:
        return [{ x: 2, y: 2 }, { x: 3, y: 1 }, { x: 2, y: 4 }, { x: 4, y: 3 }];
      default:
        return [];
    }
  };

  const [obstacles, setObstacles] = useState(getObstacles(level));

  useEffect(() => {
    setObstacles(getObstacles(level));
    setPosition({ x: 0, y: 0 });
    setMessage('');
  }, [level]);

  useEffect(() => {
    if (position.x === gridSize - 1 && position.y === gridSize - 1) {
      setMessage('¡Felicitaciones! Has llegado al destino.');
    } else {
      setMessage('');
    }
  }, [position]);

  const isObstacle = (x, y) => {
    return obstacles.some(obstacle => obstacle.x === x && obstacle.y === y);
  };

  const move = (direction) => {
    setPosition((prev) => {
      const newPosition = { ...prev };
      switch (direction) {
        case 'norte':
          if (newPosition.y > 0 && !isObstacle(newPosition.x, newPosition.y - 1)) newPosition.y -= 1;
          break;
        case 'sur':
          if (newPosition.y < gridSize - 1 && !isObstacle(newPosition.x, newPosition.y + 1)) newPosition.y += 1;
          break;
        case 'este':
          if (newPosition.x < gridSize - 1 && !isObstacle(newPosition.x + 1, newPosition.y)) newPosition.x += 1;
          break;
        case 'oeste':
          if (newPosition.x > 0 && !isObstacle(newPosition.x - 1, newPosition.y)) newPosition.x -= 1;
          break;
        default:
          break;
      }
      return newPosition;
    });
  };

  const executeCommands = () => {
    const commandList = commands.split(',').map(cmd => cmd.trim().match(/mover\((.*?)\)/)?.[1]);
    commandList.forEach((command, index) => {
      if (command) {
        setTimeout(() => {
          move(command);
        }, index * 500); // Delay each move for animation
      }
    });
  };

  return (
    <div className="App">
      <NivelSelector setLevel={setLevel} />
      <Tablero position={position} obstacles={obstacles} />
      <ControlPanel commands={commands} setCommands={setCommands} executeCommands={executeCommands} />
      {message && <div className="message">{message}</div>}
    </div>
  );
};

const NivelSelector = ({ setLevel }) => {
  return (
    <div className="level-selector">
        <h2>Instrucicones</h2>
        <h3>Mover el cuadrado que se encuentra en azul y hacerlo llegar hasta el vertice inferior derecho (abajo ala derecha)</h3>
        <h3>Escribi los comandos mover(norte), mover(sur), mover(este), y  mover(oeste) separado por comas y ejecuta el codigo</h3>
      <button onClick={() => setLevel(1)}>Nivel 1 </button>
      <button onClick={() => setLevel(2)}>Nivel 2 </button>
      <button onClick={() => setLevel(3)}>Nivel 3 </button>
    </div>
  );
};

const Tablero = ({ position, obstacles }) => {
  const rows = Array(gridSize).fill(null);
  const cols = Array(gridSize).fill(null);

  return (
    <div className="board">
 
      {rows.map((_, rowIndex) => (
        <div key={rowIndex} className="row">
          {cols.map((_, colIndex) => (
            <div
              key={colIndex}
              className={`cell ${position.x === colIndex && position.y === rowIndex ? 'active' : ''} ${obstacles.some(obstacle => obstacle.x === colIndex && obstacle.y === rowIndex) ? 'obstacle' : ''} ${position.x === gridSize - 1 && position.y === gridSize - 1 && colIndex === gridSize - 1 && rowIndex === gridSize - 1 ? 'goal' : ''}`}
            ></div>
          ))}
        </div>
      ))}
    </div>
  );
};

const ControlPanel = ({ commands, setCommands, executeCommands }) => {
  const handleCommandsChange = (e) => {
    setCommands(e.target.value);
  };

  return (
    <div className="control-panel">
          
      <textarea
        value={commands}
        onChange={handleCommandsChange}
        placeholder="mover(norte), mover(este), mover(sur)"
        rows="5"
        cols="40"
      />
      <button onClick={executeCommands}>Ejecutar</button>
    </div>
  );
};

export default App;
