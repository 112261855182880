import { useState, useEffect } from "react";
import Modal from "./modal"
import { useNavigate } from "react-router-dom";
import {Provincias } from "./datos";
import * as React from 'react';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import { Button } from "@mui/material";
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
//import overbookingData from "./overbooking";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const Lotes = (props) => {
    //configuracion de Hooks
   
    const [inscripcion, setInscripcion] = useState({
    })
     

const viajar = () => {
   props.viajar(inscripcion)
   }

    const handleChange = (e) => {
        setInscripcion(e.target.value)
    }
    
// renderiza la data table
return (
    <>
      <br/>
      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                               A donde
                            </InputLabel>
                            <NativeSelect
                                defaultValue={30}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'id_encargado',
                                    id: 'uncontrolled-native',

                                }}
                            
                            >  
                             <option value={'0'}> Elegir</option>
                             <option value={'0'}> Buenos Aires</option>
                             <option value={'1'}> Catamarca</option>
                             <option value={'2'}> Chaco</option>
                             <option value={'3'}> Chubut</option>
                             <option value={'4'}> Córdoba</option>
                             <option value={'5'}> Corrientes</option>
                             <option value={'6'}> Entre Ríos</option>
                             <option value={'7'}> Formosa</option>
                             <option value={'8'}> Jujuy</option>
                             <option value={'9'}> La Pampa</option>
                             <option value={'10'}> La Rioja</option>
                             <option value={'11'}> Mendoza</option>
                             <option value={'12'}> Misiones</option>
                             <option value={'13'}> Neuquén</option>
                             <option value={'14'}> Río Negro</option>
                             <option value={'15'}> Salta</option>
                             <option value={'16'}> San Juan</option>
                             <option value={'17'}> San Luis</option>
                             <option value={'18'}> Santa Cruz</option>
                             <option value={'19'}> Santa Fe</option>
                             <option value={'20'}> Santiago del Estero</option>
                             <option value={'21'}> Tierra del Fuego</option>
                             <option value={'22'}> Tucumán</option>
                             
                            

                   

                            </NativeSelect>
                            <Button variant='contained' onClick={viajar}>Viajar</Button>
   
    </>
)
}

export default Lotes;