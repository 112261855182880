import React from 'react';
import dinosaurImg from '../Assets/cuqui1.png';

const Dinosaur = ({ y, height }) => {
  return (
    <div style={{
      position: 'absolute',
      bottom: `${y}px`,
      left: '50px',
      width: '40px',
      height: `${height}px`,
      backgroundImage: `url(${dinosaurImg})`,
      backgroundSize: 'cover'
    }}></div>
  );
};

export default Dinosaur;

