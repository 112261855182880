import React, { useEffect, useState, } from "react";
import servicio1 from '../services/servicio1';
import { useNavigate } from "react-router-dom";

const SimplePage = () => {
  const [formData, setFormData] = useState();
  const [estado, setEstado] = useState();
  const navigate = useNavigate();
  // Función para manejar cambios en los campos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    console.log(formData)
  };

  useEffect(() => {
    traer()
}, [])

const traer = async ()=>{
 // await servicio1.probar(formData)
 // const rta = await servicio1.traer(formData)


  setEstado("rta")
}


  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
  
    e.preventDefault();
    const rta = await servicio1.crear(formData)
    alert(rta)
    // Aquí puedes hacer lo que quieras con los datos del formulario
   
  };
  return (
   <>
      <form onSubmit={handleSubmit}>
      <div>
        <label>Nombre:</label>
        <input
          type="text"
          name='"question"'
         
          onChange={handleInputChange}
        />
      </div>
       
      <button on      onClick={() => navigate('/provincia')} >Juego 1 Provincia</button><br/>  
      <button on      onClick={() => navigate('/fan-argentino')} >Juego 2 Fan Argentino</button><br/>  
      <button on      onClick={() => navigate('/botella')} >Juego 3 Botellas</button><br/>  
      <button on      onClick={() => navigate('/mario')} >Juego 4 Mario</button><br/>  
      <button on      onClick={() => navigate('/charla')} >Juego del tablero</button><br/> 
      <button on      onClick={() => navigate('/preguntados')} >Preguntados</button><br/> 
    </form>
    <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>
 nombre
   </>
  );
};

export default SimplePage;
