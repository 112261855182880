import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";
import '../votacion/VoteOption.css';

import servicio from '../../services/servicio1';
import comida from '../../Assets/votacion/comida.png';
import umi from '../../Assets/votacion/umi.jpg';
import blooddeath from '../../Assets/votacion/blooddeath.jpeg';
import fani from '../../Assets/votacion/fani.jpeg';
import debbi from '../../Assets/votacion/debbi.jpeg';
import Nekocos from '../../Assets/votacion/Nekocos.png';
import vanii from '../../Assets/votacion/vanii.jpg';
import navori from '../../Assets/votacion/navori.jpeg';
import mistique from '../../Assets/votacion/Mistique.jpg';
import Nekocoss from '../../Assets/votacion/Nekocos.jpg';
import Daegutan from '../../Assets/votacion/daegutan.jpg';
import lixie from '../../Assets/votacion/lixie.jpg';
import limonadas from '../../Assets/votacion/limonadas.jpg';
import bazaru from '../../Assets/votacion/bazaru.jpg';
import imagenDefault from '../../Assets/votacion/comida.png'; // Imagen por defecto.
import delicat from '../../Assets/votacion/delicat.jpg';
import tarotpanda from '../../Assets/votacion/tarotpanda.png';
import mishky from '../../Assets/votacion/mishky.jpg';
import moka from '../../Assets/votacion/moka.png';
import midori from '../../Assets/votacion/midori.jpg';
import grido from '../../Assets/votacion/grido.jpg';
import kapaobento from '../../Assets/votacion/kapaobento.jpg';
import nakama from '../../Assets/votacion/nakama.jpg';
import leemechi from '../../Assets/votacion/leemechi.jpg';
import thesy from '../../Assets/votacion/thesy.jpg';
import andy from '../../Assets/votacion/andy.jpg';
import slayb from '../../Assets/votacion/slayb.png';
import kahala from '../../Assets/votacion/kahala.jpg';
import madai from '../../Assets/votacion/madai.jpeg';
import tomorrow from '../../Assets/votacion/tomorrow.jpg';
import cielo from '../../Assets/votacion/cielo.jpeg';
import oneforfest from '../../Assets/votacion/oneforfest.jpeg';
import kanime from '../../Assets/votacion/kanime.jpg';
import animefest from '../../Assets/votacion/animefest.jpeg';
import Geek from '../../Assets/votacion/Geek.jpg';
import alemito from '../../Assets/votacion/alemito.jpg';
import carpinchita from '../../Assets/votacion/carpinchita.jpeg';
import jl from '../../Assets/votacion/jl.jpeg';
import nix from '../../Assets/votacion/nix.jpeg';
import solarfox from '../../Assets/votacion/solarfox.jpg';
import pakatopia from '../../Assets/votacion/pakatopia.jpg';
import cbkpop from '../../Assets/votacion/cbkpop.jpg';
import cteskpoop from '../../Assets/votacion/cteskpoop.jpeg';
import karen from '../../Assets/votacion/karen.jpeg';
import danielroa from '../../Assets/votacion/danielroa.jpeg';
import beratain from '../../Assets/votacion/beratain.jpeg';
import anubis from '../../Assets/votacion/anubis.jpeg';
import Marcosmorlio from '../../Assets/votacion/Marcosmorlio.jpeg';
import matias from '../../Assets/votacion/matias.jpeg';
import arabella from '../../Assets/votacion/arabella.jpeg';
import Giovanni from '../../Assets/votacion/Giovanni.jpg';
import Khala from '../../Assets/votacion/Khala.jpg';
import fondo from '../../Assets/votacion/fondocel2.jpg';
import Zaira from '../../Assets/votacion/Zaira.jpeg';
import gam3 from '../../Assets/votacion/gam3.jpeg';

const imageMapping = {
  comida: comida, // Imagen asociada a la opción "comida".
  Umi: umi,       // Imagen asociada a la opción "umi".
  Karen:karen,
  Matias:matias,
  Giovanni:Giovanni,
  Anubis:anubis,
  MarcosMorlio:Marcosmorlio,
  Zaira:Zaira,
  Gam3:gam3,
  Arabella:arabella,
'Daniel Roa':danielroa,
Barentain:beratain,
  BlooDeath: blooddeath,
  JLfotografia: jl,
  Carpinchita: carpinchita,
  Fani: fani,
  Pakatopia:pakatopia,
  'Sb.Redes': nix,
  'Corrientes kpop festa':cteskpoop,
  CBKpop:cbkpop,
  Lixie: lixie,
  AnimeFest: animefest,
  Alemito: alemito,
  Debbi: debbi,
  Nekocos: Nekocos,
  Mishky: mishky,
  Solarfox:solarfox,
  GeekFest: Geek,
  Khala:Khala,
  'Neko cos': Nekocoss,
  'Tarot del panda': tarotpanda,
  'Lee Mechi': leemechi,
  Cielo: cielo,
  OneForFest: oneforfest,
  KappaObento: kapaobento,
  Kanime: kanime,
  Nakama: nakama,
  Moka: moka,
  Grido: grido,
  Madai: madai,
  SlayB: slayb,
  Mechi: leemechi,
  Kahala: kahala,
  Vanii: vanii,
  Andysan: andy,
  Andy: andy,
  Navori: navori,
  Mystique: mistique,
  Daegutan: Daegutan,
  Limonaditas: limonadas,
  Delicat: delicat,
  Midori: midori,
  Thesy: thesy,
  Bazaru: bazaru,
  Leemechi: leemechi,
  Tomorrow: tomorrow,
  default: imagenDefault, // Imagen por defecto.
};
const VotingCategory = ({ onVote, disableVoting }) => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [modalImage, setModalImage] = useState(null); // Estado para mostrar la imagen en el modal

  // Detectar si es dispositivo móvil
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Ajusta el tamaño según tus necesidades.
    };

    checkMobile(); // Comprobar en el montaje inicial.
    window.addEventListener('resize', checkMobile); // Actualizar al redimensionar.

    return () => {
      window.removeEventListener('resize', checkMobile); // Limpiar el listener.
    };
  }, []);

  // Traer datos desde el servicio
  const traer = async () => {
    try {
      const historial = await servicio.traervotos();
      setOptions(historial);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    traer();
  }, []);

  // Agrupar opciones por categoría y subcategoría
  const groupedOptions = options.reduce((acc, option) => {
    const { category, subcategory } = option;
    if (!acc[category]) acc[category] = {};
    if (!acc[category][subcategory]) acc[category][subcategory] = [];
    acc[category][subcategory].push(option);
    return acc;
  }, {});

  // Cerrar el modal
  const closeModal = () => {
    setModalImage(null);
  };

  return (<>
      <div className="fixed-text">Blaze Awards - www.cuquicalvano.com</div>
    <div className="voting-categories">
      {isMobile ? (
        Object.entries(groupedOptions).map(([category, subcategories]) => (
          <div key={category} className="category-group" style={{ backgroundColor: '#70BD44', margin: '20px 0', padding: '20px', borderRadius: '15px', opacity: 0.85 }}>
            <h2 className="category-title" style={{ color: 'white' }}> {category}</h2>
            {Object.entries(subcategories).map(([subcategory, options]) => (
              <details key={subcategory} style={{ margin: '10px 0', color: 'white' }}>
                <summary style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '16px' }}>Subcategoría: {subcategory}</summary>
                <div className="vote-options-grid" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginTop: '10px' }}>
                  {options.map((option) => {
                    const imageSrc = imageMapping[option.name] || imageMapping['default'];
                    const percentage =
                    options.reduce((sum, opt) => sum + opt.votes, 0) > 0
                      ? (option.votes /
                          options.reduce((sum, opt) => sum + opt.votes, 0)) *
                        100
                      : 0;

                    return (
                      <div key={option.id} className="vote-option" style={{ textAlign: 'center', width: '200px' }}>
                        <img
                          src={imageSrc}
                          alt={option.name}
                          style={{ maxWidth: '100%', borderRadius: '10px', cursor: 'pointer' }}
                          onClick={() => setModalImage(imageSrc)} // Abrir modal con la imagen seleccionada
                        />
                        <h4>{option.name}</h4>
                        <p style={{ marginBottom: '10px', fontSize: '14px' }}>{option.description}</p>
                            {!disableVoting && (
                              <button
                                onClick={() => navigate('/votacion')}
                                style={{
                                  backgroundColor: '#007BFF',
                                  color: 'white',
                                  border: 'none',
                                  borderRadius: '5px',
                                  padding: '10px 15px',
                                  cursor: 'pointer',
                                }}
                              >
                                Votar
                              </button>
                            )}
                            <div className="vote-percentage" style={{ marginTop: '10px' }}>
                              <div
                                className="progress-bar"
                                style={{
                                  backgroundColor: '#f1f1f1',
                                  borderRadius: '5px',
                                  overflow: 'hidden',
                                  height: '10px',
                                }}
                              >
                                <div
                                  className="progress-fill"
                                  style={{
                                    width: `${percentage}%`,
                                    backgroundColor: '#007BFF',
                                    height: '100%',
                                  }}
                                />
                              </div>
                           <p style={{ marginTop: '5px', fontSize: '12px' }}>
                                {percentage.toFixed(2)}% - Votos: {option.votes}
                              </p>
                            </div> 
                     
                      </div>
                    );
                  })}
                </div>
              </details>
            ))}
          </div>
        ))
      ) : (
        Object.entries(groupedOptions).map(([category, subcategories]) => (
          <div
          
          >
<div
  key={category}
  className="category-group"
  style={{
    backgroundImage: `url(${fondo})`, // Imagen de fondo
    backgroundSize: 'cover', // Escala la imagen para cubrir todo el fondo
    backgroundPosition: 'center', // Centra la imagen
    backgroundRepeat: 'no-repeat', // No repetir la imagen
    padding: '20px', // Ajusta el tamaño del contenido
    margin: '20px 0',
    borderRadius: '15px', // Bordes redondeados
    opacity: 0.95, // Transparencia del fondo
  }}
>
              <h1 className="category-title" style={{ color: 'white', opacity: 0.7 }}>
                Categoría: {category}
              </h1>
              {Object.entries(subcategories).map(([subcategory, options]) => (
  <div key={subcategory} className="subcategory-group">
    <h3 className="subcategory-title" style={{ color: 'white' }}>
      Subcategoría: {subcategory}
    </h3>
    <div
      className="vote-options-grid"
      style={{
        display: 'flex',
        gap: '20px',
        flexWrap: 'wrap',
      }}
    >
      {options.map((option) => {
        const imageSrc = imageMapping[option.name] || imageMapping['default'];
        const percentage =
          options.reduce((sum, opt) => sum + opt.votes, 0) > 0
            ? (option.votes /
                options.reduce((sum, opt) => sum + opt.votes, 0)) *
              100
            : 0;

        return (
          <div
            key={option.id}
            className="vote-option"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)', // Fondo blanco con opacidad
              padding: '15px',
              borderRadius: '10px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              width: '200px',
            }}
          >
            <div
              className="option-image-wrapper"
              style={{
                marginBottom: '10px',
              }}
            >
              <img
                src={imageSrc}
                alt={option.name}
                className="option-image"
                style={{
                  maxWidth: '100%',
                  borderRadius: '10px',
                  opacity: 1, // Asegura que las imágenes tengan opacidad completa
                }}
                onError={(e) => (e.target.src = imageMapping['default'])}
                onClick={() => setModalImage(imageSrc)}
              />
            </div>
            <div className="option-info">
              <h4
                style={{
                  marginBottom: '10px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                {option.name}
              </h4>
              <p
                style={{
                  marginBottom: '10px',
                  fontSize: '14px',
                }}
              >
                {option.description}
              </p>
              {!disableVoting && (
                <button
                  onClick={() => navigate('/votacion')}
                  style={{
                    backgroundColor: '#007BFF',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '10px 15px',
                    cursor: 'pointer',
                  }}
                >
                  Votar
                </button>
              )}
                <div className="vote-percentage" style={{ marginTop: '10px' }}>
                <div
                  className="progress-bar"
                  style={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    height: '10px',
                  }}
                >
                      <div
                    className="progress-fill"
                    style={{
                      width: `${percentage}%`,
                      backgroundColor: '#007BFF',
                      height: '100%',
                    }}
                  />
                </div>
         <p
                  style={{
                    marginTop: '5px',
                    fontSize: '12px',
                  }}
                >
                  {percentage.toFixed(2)}% - Votos: {option.votes}
                </p> 
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
))}

               </div>
            </div>
          ))
      )}

      {/* Modal para mostrar la imagen */}
      {modalImage && (
        <div
          className="modal-overlay"
          onClick={closeModal}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              position: 'relative',
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            <img
              src={modalImage}
              alt="Modal"
              style={{ maxWidth: '90vw', maxHeight: '90vh', borderRadius: '10px' }}
            />
            <button
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'none',
                border: 'none',
                color: 'white',
                fontSize: '20px',
                cursor: 'pointer',
              }}
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default VotingCategory;
