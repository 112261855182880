import { useState, useEffect } from "react";
import Modal from "./modal"
import { useNavigate } from "react-router-dom";
import { Provincias } from "./datos";
import * as React from 'react';
import Persecucion from "./persecucion"
import MuiAlert from '@mui/material/Alert';
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import SeccionProvincia from "./provincia"
import SeccionPreguntar from "./preguntar"
import SeccionViajar from "./provincia"
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Comenzarjuego from "./modalcomenzarjuego"
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Navbar from './Navbar'
import Viajar from "./viajar"
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PinDropIcon from '@mui/icons-material/PinDrop';
//import overbookingData from "./overbooking";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.line[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.line[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <LocalAirportIcon />,
    2: <LiveHelpIcon />,
    3: <PinDropIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['Provincia', 'Preguntar', 'Viajar'];
const drawerWidth = 280;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Lotes = () => {
  //configuracion de Hooks
  const [persecucionact, setPersecucionact] = useState(0);
  const [provinciaact, setProvinciaact] = useState(0);
  const [provinciault, setProvinciault] = useState(0);
  const [provinciaprox, setProvinciaprox] = useState(0);
  const [adivinados, setAdivinados] = React.useState([]);
  const [seccion, setSeccion] = useState(10);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const Juegonuevo = () => {
    let nuevoNumero = Math.floor(Math.random() * 22);
    setProvinciaact(nuevoNumero)
    setProvinciault(nuevoNumero)
    adivinados.push(nuevoNumero)
    nuevoNumero = Math.floor(Math.random() * 22);
    do {
      nuevoNumero = Math.floor(Math.random() * 22); // Genera números entre 0 y 25
    } while (adivinados.includes(nuevoNumero));
    setProvinciaprox(nuevoNumero)
    adivinados.push(nuevoNumero)
    setSeccion(0)
  };

  const menuItems = [
    {
      text: 'Provincia',
      icon: "",
      path: '/legales/clientes'
    },

    {
      text: 'Preguntar',
      icon: "",
      path: '/legales/clientes'
    },
    {
      text: 'Viajar',
      icon: "",
      path: '/legales/clientes'
    },

  ];
  const handleClick = (index) => {
    console.log(index)
    setSeccion(index)
  };
  // renderiza la data table
  return (
    <>


      {isMatch ? <>


        {seccion === 10 ? <>

          <Modal
            Nombreboton={"instrucciones"}
            contenido={"Eres un fanatico de x persona. Recientemente te enteraste q vino a la Argentina con otro nombre. Tenes los datos para preguntar a que parte del paise se fue."} />

          <Comenzarjuego
            comenzar={() => {
              let nuevoNumero = Math.floor(Math.random() * 22);
              setProvinciaact(nuevoNumero)
              setProvinciault(nuevoNumero)
              adivinados.push(nuevoNumero)
              nuevoNumero = Math.floor(Math.random() * 22);
              do {
                nuevoNumero = Math.floor(Math.random() * 22); // Genera números entre 0 y 25
              } while (adivinados.includes(nuevoNumero));
              setProvinciaprox(nuevoNumero)
              adivinados.push(nuevoNumero)
              setSeccion(0)
            }} /> <br />
          <br />
        </> : <>
        <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
        {steps.map((label,index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}  onClick={() => setSeccion(index)}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
          {seccion === 0 ? <>

            <Persecucion
              numero={3} />
            <SeccionProvincia provinciaact={provinciaact}
              provinciault={provinciault}
              provinciaprox={provinciaprox}


            />
          </> : <>
            {seccion === 1 ? <>

              <Persecucion
                numero={persecucionact} />
              <SeccionPreguntar
                provinciaact={provinciaact}
                provinciault={provinciault}
                provinciaprox={provinciaprox} 
                subirsospecha= {(e)=>{
                  setPersecucionact(persecucionact+e)
                }}/>
            </> : <>

              <Viajar
                provinciaact={provinciaact}
                provinciault={provinciault}
                provinciaprox={provinciaprox}
                viajar={(e) => {
                  setPersecucionact(persecucionact+1)
                  setProvinciaact(e)
                  if (e == provinciaprox) {
                    setProvinciault(e)///la ultima quedad la proximas

                    let nuevoNumero = Math.floor(Math.random() * 22);
                    do {
                      nuevoNumero = Math.floor(Math.random() * 22); // Genera números entre 0 y 25
                    } while (adivinados.includes(nuevoNumero));
                    setProvinciaprox(nuevoNumero)
                    adivinados.push(nuevoNumero)
                  }
                  setSeccion(0)
                }}

              /></>}

          </>}

        </>}







      </> : <>
        <br />   <br />   <br />
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          {adivinados.length > 0 ? <>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
              }}
              variant="permanent"
              anchor="left"
            >

              <Navbar
                logout={{}} />


              <Toolbar />

              <Toolbar />
              <Divider />
              <List>
                {menuItems.map((item, index) => (
                  <ListItem
                    button
                    key={item.text}
                    onClick={() => {
                      handleClick(index)
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>

              <Divider />

            </Drawer>
          </> : <>


          </>}
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
          >
            <Toolbar />




            {seccion === 10 ? <>

              <Modal
                Nombreboton={"instrucciones"}
                contenido={"  Bienvenido al Fan argentino: Argentina. Eres un apasionado fan de la superestrella [nombre de la celebridad], quien ha llegado a Argentina para una gira única. Pero hay un problema: ¡ha desaparecido misteriosamente! Como su fan número uno, decides embarcarte en una aventura por todo el país para encontrarlo antes de que las sospechas de la policía te atrapen. ¡Prepárate para un viaje emocionante lleno de intrigas, pistas y encuentros inesperados!"} />

              <Comenzarjuego
                comenzar={() => {
                  let nuevoNumero = Math.floor(Math.random() * 22);
                  setProvinciaact(nuevoNumero)
                  setProvinciault(nuevoNumero)
                  adivinados.push(nuevoNumero)
                  nuevoNumero = Math.floor(Math.random() * 22);
                  do {
                    nuevoNumero = Math.floor(Math.random() * 22); // Genera números entre 0 y 25
                  } while (adivinados.includes(nuevoNumero));
                  setProvinciaprox(nuevoNumero)
                  adivinados.push(nuevoNumero)
                  setSeccion(0)
                }} /> <br />
              <br />
            </> : <>
              {seccion === 0 ? <>

                <Persecucion
                  numero={persecucionact} />
                <SeccionProvincia provinciaact={provinciaact}
                  provinciault={provinciault}
                  provinciaprox={provinciaprox}


                />
              </> : <>
                {seccion === 1 ? <>

                  <Persecucion
                    numero={persecucionact} />
                  <SeccionPreguntar
                    provinciaact={provinciaact}
                    provinciault={provinciault}
                    provinciaprox={provinciaprox}
                    subirsospecha= {(e)=>{
                      setPersecucionact(persecucionact+e)
                    }}/>
                </> : <>

                  <Viajar
                    provinciaact={provinciaact}
                    provinciault={provinciault}
                    provinciaprox={provinciaprox}
                    viajar={(e) => {
                      setPersecucionact(persecucionact+1)
                      setProvinciaact(e)
                      if (e == provinciaprox) {
                        setProvinciault(e)///la ultima quedad la proximas
                     
                        let nuevoNumero = Math.floor(Math.random() * 22);
                        do {
                          nuevoNumero = Math.floor(Math.random() * 22); // Genera números entre 0 y 25
                        } while (adivinados.includes(nuevoNumero));
                        setProvinciaprox(nuevoNumero)
                        adivinados.push(nuevoNumero)
                      }
                      setSeccion(0)
                    }}

                  /></>}

              </>}

            </>}


          </Box>

        </Box>



      </>}



    </>
  )
}

export default Lotes;