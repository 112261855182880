import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Efectodeltecto from "./efectodeltexto"


import React, { useEffect, useState, Fragment } from "react";


export default function SelectTextFields(props) {
  const [open, setOpen] = React.useState(false);
  //const usuario  = useUser().userContext

  const [seleccion, seSeleccion] = useState([''])




  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = (e) => {
 
    seSeleccion({ ...seleccion, [e.target.name]: e.target.value })
    console.log(seleccion)
  }
  ////
  
  const [currency, setCurrency] = React.useState('EUR');

  /*   const handleChange = (event) => {
      setCurrency(event.target.value);
    }; */


  return (

    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Button variant="outlined" onClick={handleClickOpen}>

       {props.Nombreboton}
      </Button>
      <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{props.titulo}</DialogTitle>
      
        <DialogContent>
          <div>
           
          <Efectodeltecto text={props.contenido}/>
    
           
    
        </div>
      </DialogContent>
       <Button onClick={handleClose} variant='contained' >
               Listo
            </Button>
    </Dialog>
    </Box >




  );
}