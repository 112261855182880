


import Formulario from '../../components/fana/principal'
import React, { useEffect, useState } from "react";
import NAv from '../../components/fana/Navbar'
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function Paginas() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));


   
            return (
                <>
               
                   {isMatch ? <>
                    <NAv style={{transform:"scale(0.5)", zIndex: 2 }}/>
                
            <div style={{ position: 'absolute', top: 0, left: -50, width: '100%', height: '100%',transform:"scale(0.7)" }} >
    
    <Formulario/>

</div>
           
           </>:<>
           <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} >
    
                <Formulario/>
          
     </div>
     </>}
                
          
                </>
           
            );
        
        }