// src/Game.js
import React, { useState, useEffect } from 'react';
import Character from './Character';
import Coin from './Coin';
import Platform from './Platform';

const Game = () => {
  const [characterX, setCharacterX] = useState(50);
  const [characterY, setCharacterY] = useState(20);
  const [coins, setCoins] = useState([
    { id: 1, x: 100, y: 70 },
    { id: 2, x: 200, y: 100 },
  ]);
  const [platforms, setPlatforms] = useState([
    { id: 1, x: 0, y: 0, width: 800, height: 20 },
    { id: 2, x: 150, y: 80, width: 100, height: 20 },
  ]);
  const [isJumping, setIsJumping] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [score, setScore] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        setCharacterX((prevX) => prevX + 5);
      } else if (event.key === 'ArrowLeft') {
        setCharacterX((prevX) => prevX - 5);
      } else if (event.key === 'ArrowUp' && !isJumping) {
        setIsJumping(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isJumping]);

  useEffect(() => {
    let jumpInterval;
    if (isJumping) {
      jumpInterval = setInterval(() => {
        setCharacterY((prevY) => {
          if (prevY >= 150) {
            setIsJumping(false);
            return prevY;
          }
          return prevY + 5;
        });
      }, 20);
    } else if (characterY > 20) {
      jumpInterval = setInterval(() => {
        setCharacterY((prevY) => (prevY <= 20 ? 20 : prevY - 5));
      }, 20);
    }

    return () => {
      clearInterval(jumpInterval);
    };
  }, [isJumping, characterY]);

  useEffect(() => {
    const checkCollisions = () => {
      let newCoins = coins.filter((coin) => {
        const collision =
          characterX < coin.x + 20 &&
          characterX + 30 > coin.x &&
          characterY < coin.y + 20 &&
          characterY + 50 > coin.y;

        if (collision) {
          setScore((prevScore) => prevScore + 1);
          return false;
        }
        return true;
      });

      if (newCoins.length !== coins.length) {
        setCoins(newCoins);
      }

      const onPlatform = platforms.some((platform) => {
        return (
          characterX < platform.x + platform.width &&
          characterX + 30 > platform.x &&
          characterY <= platform.y + platform.height &&
          characterY + 50 > platform.y
        );
      });

      if (!onPlatform && !isJumping) {
        setIsGameOver(true);
      }
    };

    checkCollisions();
  }, [characterX, characterY, coins, platforms, isJumping]);

  const handleRestart = () => {
    setCharacterX(50);
    setCharacterY(20);
    setCoins([
      { id: 1, x: 100, y: 70 },
      { id: 2, x: 200, y: 100 },
    ]);
    setPlatforms([
      { id: 1, x: 0, y: 0, width: 800, height: 20 },
      { id: 2, x: 150, y: 80, width: 100, height: 20 },
    ]);
    setIsJumping(false);
    setIsGameOver(false);
    setScore(0);
  };

  if (isGameOver) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1>Game Over</h1>
        <p>Score: {score}</p>
        <button onClick={handleRestart} style={buttonStyle}>
          Restart Game
        </button>
      </div>
    );
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '800px',
        height: '400px',
        backgroundColor: 'skyblue',
        overflow: 'hidden',
      }}
    >
      <Character x={characterX} y={characterY} />
      {coins.map((coin) => (
        <Coin key={coin.id} x={coin.x} y={coin.y} />
      ))}
      {platforms.map((platform) => (
        <Platform
          key={platform.id}
          x={platform.x}
          y={platform.y}
          width={platform.width}
          height={platform.height}
        />
      ))}
      <div style={{ position: 'absolute', top: '10px', left: '10px', fontSize: '20px' }}>
        Score: {score}
      </div>
    </div>
  );
};

const buttonStyle = {
  padding: '20px',
  fontSize: '20px',
  cursor: 'pointer',
};

export default Game;
