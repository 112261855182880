


import Formulario from '../../components/componente1'
import React, { useEffect, useState } from "react";


export default function Paginas() {
  


   
            return (
                <>
           
              <div  >
        <br/>
                <Formulario/>
                
                </div>
              
     
                
          
                </>
           
            );
        
        }